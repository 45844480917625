import {
  GET_CAREERS_LIST,
  GET_CAREERS_LIST_SUCCESS,
  GET_CAREERS_LIST_FAILURE,
  GET_CAREERS_POSITION,
  GET_CAREERS_POSITION_SUCCESS,
  GET_CAREERS_POSITION_FAILURE,
  CREATE_CAREERS_POSITION,
  CREATE_CAREERS_POSITION_SUCCESS,
  CREATE_CAREERS_POSITION_FAILURE,
  UPDATE_CAREERS_POSITION,
  UPDATE_CAREERS_POSITION_SUCCESS,
  UPDATE_CAREERS_POSITION_FAILURE,
  DELETE_CAREERS,
  DELETE_CAREERS_SUCCESS,
  DELETE_CAREERS_FAILURE,
  DELETE_CAREERS_LIST,
  DELETE_CAREERS_LIST_SUCCESS,
  DELETE_CAREERS_LIST_FAILURE,
  MOCK_GET_COUPON_CODE,
  MOCK_GET_COUPON_CODE_SUCCESS,
  MOCK_GET_COUPON_CODE_FAILURE,
  MOCK_ADD_COUPON_CODE,
  MOCK_ADD_COUPON_CODE_SUCCESS,
  MOCK_ADD_COUPON_CODE_FAILURE,
  MOCK_DELETE_COUPON_CODE,
  MOCK_DELETE_COUPON_CODE_SUCCESS,
  MOCK_DELETE_COUPON_CODE_FAILURE,
  MOCK_GET_INTERVIEWS,
  MOCK_GET_INTERVIEWS_SUCCESS,
  MOCK_GET_INTERVIEWS_FAILURE,
  MOCK_INTERVIEW_RESENT,
  MOCK_INTERVIEW_RESENT_SUCCESS,
  MOCK_INTERVIEW_RESENT_FAILURE,
  MOCK_CANDIDATES,
  MOCK_CANDIDATES_SUCCESS,
  MOCK_CANDIDATES_FAILURE,
  MOCK_SUBSCRIPTION,
  MOCK_SUBSCRIPTION_SUCCESS,
  MOCK_SUBSCRIPTION_FAILURE,
  MOCK_CANDIDATE_UPDATE,
  MOCK_CANDIDATE_UPDATE_SUCCESS,
  MOCK_CANDIDATE_UPDATE_FAILURE,
  MOCK_CANDIDATE_STATUS_UPDATE,
  MOCK_CANDIDATE_STATUS_UPDATE_SUCCESS,
  MOCK_CANDIDATE_STATUS_UPDATE_FAILURE,
  MOCK_INTERVIEW_APPROVE,
  MOCK_INTERVIEW_APPROVE_SUCCESS,
  MOCK_INTERVIEW_APPROVE_FAILURE,
  MOCK_INTERVIEW_NOT_APPROVE,
  MOCK_INTERVIEW_NOT_APPROVE_SUCCESS,
  MOCK_INTERVIEW_NOT_APPROVE_FAILURE,
} from "../action/types";

const INIT_STATE = {
  loading: false,
  careersList: null,
  careersPositionList: null,
  couponList: null,
  interviewList: null,
  candidateList: null,
  subscriptionList: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case MOCK_INTERVIEW_APPROVE:
      return { ...state, loading: true };
    case MOCK_INTERVIEW_APPROVE_SUCCESS:
      return { ...state, loading: false };
    case MOCK_INTERVIEW_APPROVE_FAILURE:
      return { ...state, loading: false };

    case MOCK_INTERVIEW_NOT_APPROVE:
      return { ...state, loading: true };
    case MOCK_INTERVIEW_NOT_APPROVE_SUCCESS:
      return { ...state, loading: false };
    case MOCK_INTERVIEW_NOT_APPROVE_FAILURE:
      return { ...state, loading: false };

    //CAREER
    case GET_CAREERS_LIST:
      return { ...state, loading: true };
    case GET_CAREERS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        careersList: action.payload,
      };
    case GET_CAREERS_LIST_FAILURE:
      return { ...state, loading: false };
    case GET_CAREERS_POSITION:
      return { ...state, loading: true };
    case GET_CAREERS_POSITION_SUCCESS:
      return {
        ...state,
        loading: false,
        careersPositionList: action.payload,
      };
    case GET_CAREERS_POSITION_FAILURE:
      return { ...state, loading: false };
    case CREATE_CAREERS_POSITION:
      return { ...state, loading: true };
    case CREATE_CAREERS_POSITION_SUCCESS:
      return { ...state, loading: false };
    case CREATE_CAREERS_POSITION_FAILURE:
      return { ...state, loading: false };
    case UPDATE_CAREERS_POSITION:
      return { ...state, loading: true };
    case UPDATE_CAREERS_POSITION_SUCCESS:
      return { ...state, loading: false };
    case UPDATE_CAREERS_POSITION_FAILURE:
      return { ...state, loading: false };
    case DELETE_CAREERS:
      return { ...state, loading: true };
    case DELETE_CAREERS_SUCCESS:
      return { ...state, loading: false };
    case DELETE_CAREERS_FAILURE:
      return { ...state, loading: false };
    case DELETE_CAREERS_LIST:
      return { ...state, loading: true };
    case DELETE_CAREERS_LIST_SUCCESS:
      return { ...state, loading: false };
    case DELETE_CAREERS_LIST_FAILURE:
      return { ...state, loading: false };
    case MOCK_GET_COUPON_CODE:
      return { ...state, loading: true };
    case MOCK_GET_COUPON_CODE_SUCCESS:
      return { ...state, loading: false, couponList: action.payload };
    case MOCK_GET_COUPON_CODE_FAILURE:
      return { ...state, loading: false };
    case MOCK_ADD_COUPON_CODE:
      return { ...state, loading: true };
    case MOCK_ADD_COUPON_CODE_SUCCESS:
      return { ...state, loading: false };
    case MOCK_ADD_COUPON_CODE_FAILURE:
      return { ...state, loading: false };
    case MOCK_DELETE_COUPON_CODE:
      return { ...state, loading: true };
    case MOCK_DELETE_COUPON_CODE_SUCCESS:
      return { ...state, loading: false };
    case MOCK_DELETE_COUPON_CODE_FAILURE:
      return { ...state, loading: false };
    case MOCK_GET_INTERVIEWS:
      return { ...state, loading: true };
    case MOCK_GET_INTERVIEWS_SUCCESS:
      return { ...state, loading: false, interviewList: action.payload };
    case MOCK_GET_INTERVIEWS_FAILURE:
      return { ...state, loading: false };
    case MOCK_INTERVIEW_RESENT:
      return { ...state, loading: true };
    case MOCK_INTERVIEW_RESENT_SUCCESS:
      return { ...state, loading: false };
    case MOCK_INTERVIEW_RESENT_FAILURE:
      return { ...state, loading: false };
    case MOCK_CANDIDATES:
      return { ...state, loading: true };
    case MOCK_CANDIDATES_SUCCESS:
      return { ...state, loading: false, candidateList: action.payload };
    case MOCK_CANDIDATES_FAILURE:
      return { ...state, loading: false };
    case MOCK_SUBSCRIPTION:
      return { ...state, loading: true };
    case MOCK_SUBSCRIPTION_SUCCESS:
      return { ...state, loading: false, subscriptionList: action.payload };
    case MOCK_SUBSCRIPTION_FAILURE:
      return { ...state, loading: false };
    case MOCK_CANDIDATE_UPDATE:
      return { ...state, loading: true };
    case MOCK_CANDIDATE_UPDATE_SUCCESS:
      return { ...state, loading: false };
    case MOCK_CANDIDATE_UPDATE_FAILURE:
      return { ...state, loading: false };
    case MOCK_CANDIDATE_STATUS_UPDATE:
      return { ...state, loading: true };
    case MOCK_CANDIDATE_STATUS_UPDATE_SUCCESS:
      return { ...state, loading: false };
    case MOCK_CANDIDATE_STATUS_UPDATE_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};

import {
  DASHBOARD_STATS,
  DASHBOARD_STATS_SUCCESS,
  DASHBOARD_STATS_FAILURE,
  GET_INTERVIEW,
  GET_INTERVIEW_SUCCESS,
  GET_INTERVIEW_FAILURE,
  GET_FEEDBACK_REPORT,
  GET_FEEDBACK_REPORT_SUCCESS,
  GET_FEEDBACK_REPORT_FAILURE,
  CREATE_OR_UPDATE_POSITION,
  CREATE_OR_UPDATE_POSITION_SUCCESS,
  CREATE_OR_UPDATE_POSITION_FAILURE,
  GET_CALENDAR_EVENT,
  GET_CALENDAR_EVENT_SUCCESS,
  GET_CALENDAR_EVENT_FAILURE,
  GET_POSITION,
  GET_POSITION_SUCCESS,
  GET_POSITION_FAILURE,
  DELETE_POSITION,
  DELETE_POSITION_SUCCESS,
  DELETE_POSITION_FAILURE,
  GET_POSITION_LIST,
  GET_POSITION_LIST_SUCCESS,
  GET_POSITION_LIST_FAILURE,
  UPDATE_POSITION_LIST,
  UPDATE_POSITION_LIST_SUCCESS,
  UPDATE_POSITION_LIST_FAILURE,
  GET_PREDEFINED_POSITION_LIST,
  GET_PREDEFINED_POSITION_LIST_FAILURE,
  GET_PREDEFINED_POSITION_LIST_SUCCESS,
  GET_PANELIST,
  GET_PANELIST_SUCCESS,
  GET_PANELIST_FAILURE,
  NOTIFY_PANELIST,
  NOTIFY_PANELIST_SUCCESS,
  NOTIFY_PANELIST_FAILURE,
  UPDATE_PANELIST,
  UPDATE_PANELIST_SUCCESS,
  UPDATE_PANELIST_FAILURE,
  GET_INTERVIEWER_LIST,
  GET_INTERVIEWER_LIST_FAILURE,
  GET_INTERVIEWER_LIST_SUCCESS,
  GET_INTERVIEWER_ACCOUNT_DETAILS,
  GET_INTERVIEWER_ACCOUNT_DETAILS_SUCCESS,
  GET_INTERVIEWER_ACCOUNT_DETAILS_FAILURE,
  ADD_OR_EDIT_INTERVIEWER_ACCOUNT_DETAILS,
  ADD_OR_EDIT_INTERVIEWER_ACCOUNT_DETAILS_SUCCESS,
  ADD_OR_EDIT_INTERVIEWER_ACCOUNT_DETAILS_FAILURE,
  GET_INTERVIEWER_BANK_NAME_LIST,
  GET_INTERVIEWER_BANK_NAME_LIST_SUCCESS,
  GET_INTERVIEWER_BANK_NAME_LIST_FAILURE,
  GET_INTERVIEWER_USER_INFORMATION,
  GET_INTERVIEWER_USER_INFORMATION_SUCCESS,
  GET_INTERVIEWER_USER_INFORMATION_FAILURE,
  UPDATE_INTERVIEWER_USER_INFORMATION,
  UPDATE_INTERVIEWER_USER_INFORMATION_SUCCESS,
  UPDATE_INTERVIEWER_USER_INFORMATION_FAILURE,
  UPDATE_INTERVIEWER_EDU_AND_SKILL_SET,
  UPDATE_INTERVIEWER_EDU_AND_SKILL_SET_SUCCESS,
  UPDATE_INTERVIEWER_EDU_AND_SKILL_SET_FAILURE,
  GET_INTERVIEWER_ADMIN_REMARKS_INFO,
  GET_INTERVIEWER_ADMIN_REMARKS_INFO_SUCCESS,
  GET_INTERVIEWER_ADMIN_REMARKS_INFO_FAILURE,
  GET_INCSERVE_POSITIONS,
  GET_INCSERVE_POSITIONS_SUCCESS,
  GET_INCSERVE_POSITIONS_FAILURE,
  GET_INCSERVE_REQUIREMENTS,
  GET_INCSERVE_REQUIREMENTS_SUCCESS,
  GET_INCSERVE_REQUIREMENTS_FAILURE,
  RESEND_EMAIL_VERIFICATION,
  RESEND_EMAIL_VERIFICATION_SUCCESS,
  RESEND_EMAIL_VERIFICATION_FAILURE,
  CHANGE_INTERVIEWER_STATUS,
  CHANGE_INTERVIEWER_STATUS_SUCCESS,
  CHANGE_INTERVIEWER_STATUS_FAILURE,
  ALLOW_INTERVIEWER,
  ALLOW_INTERVIEWER_SUCCESS,
  ALLOW_INTERVIEWER_FAILURE,
  VERIFY_INTERVIEWER,
  VERIFY_INTERVIEWER_SUCCESS,
  VERIFY_INTERVIEWER_FAILURE,
  CREATE_OR_UPDATE_INCSERVE_POSITIONS,
  CREATE_OR_UPDATE_INCSERVE_POSITIONS_SUCCESS,
  CREATE_OR_UPDATE_INCSERVE_POSITIONS_FAILURE,
  DELETE_INCSERVE_POSITIONS,
  DELETE_INCSERVE_POSITIONS_SUCCESS,
  DELETE_INCSERVE_POSITIONS_FAILURE,
  GET_POSITIONS_DROPDOWN,
  GET_POSITIONS_DROPDOWN_SUCCESS,
  GET_POSITIONS_DROPDOWN_FAILURE,
  GET_INCSERVE_INTERVIEWS,
  GET_INCSERVE_INTERVIEWS_SUCCESS,
  GET_INCSERVE_INTERVIEWS_FAILURE,
  CREATE_OR_UPDATE_INCSERVE_INTERVIEWS,
  CREATE_OR_UPDATE_INCSERVE_INTERVIEWS_SUCCESS,
  CREATE_OR_UPDATE_INCSERVE_INTERVIEWS_FAILURE,
  GET_INCSERVE_INTERVIEWS_BY_ID,
  GET_INCSERVE_INTERVIEWS_BY_ID_SUCCESS,
  GET_INCSERVE_INTERVIEWS_BY_ID_FAILURE,
  GET_INCSERVE_POSITIONS_BY_ID,
  GET_INCSERVE_POSITIONS_BY_ID_SUCCESS,
  GET_INCSERVE_POSITIONS_BY_ID_FAILURE,
  GET_EMPLOYER_INTERVIEW_DETAILS,
  GET_EMPLOYER_INTERVIEW_DETAILS_SUCCESS,
  GET_EMPLOYER_INTERVIEW_DETAILS_FAILURE,
  GET_EMPLOYER_POSITION_DETAILS_FAILURE,
  GET_EMPLOYER_POSITION_DETAILS_SUCCESS,
  GET_EMPLOYER_POSITION_DETAILS,
  GET_INCEXIT_INTERVIEW,
  GET_INCEXIT_INTERVIEW_SUCCESS,
  GET_INCEXIT_INTERVIEW_FAILURE,
  GET_ALL_INTERVIEWERS,
  GET_ALL_INTERVIEWERS_SUCCESS,
  GET_ALL_INTERVIEWERS_FAILURE,
  ACCEPT_INCEXIT_INTERVIEW,
  ACCEPT_INCEXIT_INTERVIEW_SUCCESS,
  ACCEPT_INCEXIT_INTERVIEW_FAILURE,
  PREVIOUSLY_ALLOTED_INTERVIEWER_LIST,
  PREVIOUSLY_ALLOTED_INTERVIEWER_LIST_SUCCESS,
  PREVIOUSLY_ALLOTED_INTERVIEWER_LIST_FAILURE,
  DESIGNATION_LIST,
  DESIGNATION_LIST_SUCCESS,
  DESIGNATION_LIST_FAILURE,
  UPDATE_INCEXIT_INTERVIEW,
  UPDATE_INCEXIT_INTERVIEW_SUCCESS,
  UPDATE_INCEXIT_INTERVIEW_FAILURE,
  CREATE_INCEXIT_INTERVIEW,
  CREATE_INCEXIT_INTERVIEW_SUCCESS,
  CREATE_INCEXIT_INTERVIEW_FAILURE,
  INCSERVE_APPROVE_FEEDBACK,
  INCSERVE_APPROVE_FEEDBACK_SUCCESS,
  INCSERVE_APPROVE_FEEDBACK_FAILURE,
  INCSERVE_CANCEL_INTERVIEW,
  INCSERVE_CANCEL_INTERVIEW_SUCCESS,
  INCSERVE_CANCEL_INTERVIEW_FAILURE,
  INCSERVE_UPDATE_PAY_PER_INTERVIEW,
  INCSERVE_UPDATE_PAY_PER_INTERVIEW_SUCCESS,
  INCSERVE_UPDATE_PAY_PER_INTERVIEW_FAILURE,
  INCSERVE_ACCEPT_INTERVIEW,
  INCSERVE_ACCEPT_INTERVIEW_SUCCESS,
  INCSERVE_ACCEPT_INTERVIEW_FAILURE,
  INCSERVE_RESCHEDULE_INTERVIEW,
  INCSERVE_RESCHEDULE_INTERVIEW_SUCCESS,
  INCSERVE_RESCHEDULE_INTERVIEW_FAILURE,
  GET_INCSERVE_INTERVIEWS_SEARCH_LIST,
  GET_INCSERVE_INTERVIEWS_SEARCH_LIST_FAILURE,
  GET_INCSERVE_INTERVIEWS_SEARCH_LIST_SUCCESS,
  CANCEL_INCEXIT_INTERVIEW,
  CANCEL_INCEXIT_INTERVIEW_SUCCESS,
  CANCEL_INCEXIT_INTERVIEW_FAILURE,
  UPDATE_INTERVIEWER_ADMIN_REMARKS_INFO,
  UPDATE_INTERVIEWER_ADMIN_REMARKS_INFO_SUCCESS,
  UPDATE_INTERVIEWER_ADMIN_REMARKS_INFO_FAILURE,
  GET_INTERVIEWER_RATINGS,
  GET_INTERVIEWER_RATINGS_SUCCESS,
  GET_INTERVIEWER_RATINGS_FAILURE,
  UPDATE_INTERVIEWER_RATINGS,
  UPDATE_INTERVIEWER_RATINGS_SUCCESS,
  UPDATE_INTERVIEWER_RATINGS_FAILURE,
  CALL_INTERVIEWER_AND_CANDIDATE,
  CALL_INTERVIEWER_AND_CANDIDATE_SUCCESS,
  CALL_INTERVIEWER_AND_CANDIDATE_FAILURE,
  GET_SIMILAR_WEBSITE_LIST,
  GET_SIMILAR_WEBSITE_LIST_SUCCESS,
  GET_SIMILAR_WEBSITE_LIST_FAILURE,
  CHANGE_PLATFORM,
  CHANGE_PLATFORM_SUCCESS,
  CHANGE_PLATFORM_FAILURE,
  ALLOT_AND_REALLOT_INTERVIEWER,
  ALLOT_AND_REALLOT_INTERVIEWER_SUCCESS,
  ALLOT_AND_REALLOT_INTERVIEWER_FAILURE,
  GET_INVOICE_DETAILS,
  GET_INVOICE_DETAILS_SUCCESS,
  GET_INVOICE_DETAILS_FAILURE,
  ALLOT_INCEXIT_INTERVIEWER,
  ALLOT_INCEXIT_INTERVIEWER_SUCCESS,
  ALLOT_INCEXIT_INTERVIEWER_FAILURE,
  SCHEDULE_INCEXIT_INTERVIEW,
  SCHEDULE_INCEXIT_INTERVIEW_SUCCESS,
  SCHEDULE_INCEXIT_INTERVIEW_FAILURE,
  GET_INCEXIT_INTERVIEWS_SEARCH_LIST,
  GET_INCEXIT_INTERVIEWS_SEARCH_LIST_SUCCESS,
  GET_INCEXIT_INTERVIEWS_SEARCH_LIST_FAILURE,
  BULK_INCEXIT_INTERVIEW,
  BULK_INCEXIT_INTERVIEW_SUCCESS,
  BULK_INCEXIT_INTERVIEW_FAILURE,
  ACCEPT_BULK_INCEXIT_INTERVIEW,
  ACCEPT_BULK_INCEXIT_INTERVIEW_SUCCESS,
  ACCEPT_BULK_INCEXIT_INTERVIEW_FAILURE,
  INCSERVE_RESCHEDULED_ACCEPT_REQUEST,
  INCSERVE_RESCHEDULED_ACCEPT_REQUEST_SUCCESS,
  INCSERVE_RESCHEDULED_ACCEPT_REQUEST_FAILURE,
  INCSERVE_POST_REVIEW_REQUEST,
  INCSERVE_POST_REVIEW_REQUEST_SUCCESS,
  INCSERVE_POST_REVIEW_REQUEST_FAILURE,
  INCSERVE_GIVE_FEEDBACK,
  INCSERVE_GIVE_FEEDBACK_SUCCESS,
  INCSERVE_GIVE_FEEDBACK_FAILURE,
  GET_INCSERVE_SINGLE_DELETE_LIST,
  GET_INCSERVE_SINGLE_DELETE_LIST_SUCCESS,
  GET_INCSERVE_SINGLE_DELETE_LIST_FAILURE,
  GET_INCSERVE_BULK_DELETE_LIST,
  GET_INCSERVE_BULK_DELETE_LIST_SUCCESS,
  GET_INCSERVE_BULK_DELETE_LIST_FAILURE,
  BULK_INCSERVE_REQUIREMENT,
  BULK_INCSERVE_REQUIREMENT_SUCCESS,
  BULK_INCSERVE_REQUIREMENT_FAILURE,
  ACCEPT_BULK_INCSERVE_REQUIREMENT,
  ACCEPT_BULK_INCSERVE_REQUIREMENT_SUCCESS,
  ACCEPT_BULK_INCSERVE_REQUIREMENT_FAILURE,
  ADD_INCEXIT_FEEDBACK,
  ADD_INCEXIT_FEEDBACK_SUCCESS,
  ADD_INCEXIT_FEEDBACK_FAILURE,
  CLOSE_BULK_INCEXIT_INTERVIEW,
  CLOSE_BULK_INCEXIT_INTERVIEW_SUCCESS,
  CLOSE_BULK_INCEXIT_INTERVIEW_FAILURE,
  RESCHEDULE_INCEXIT_INTERVIEW,
  RESCHEDULE_INCEXIT_INTERVIEW_SUCCESS,
  RESCHEDULE_INCEXIT_INTERVIEW_FAILURE,
  CLOSE_BULK_INCSERVE_REQUIREMENT,
  CLOSE_BULK_INCSERVE_REQUIREMENT_SUCCESS,
  CLOSE_BULK_INCSERVE_REQUIREMENT_FAILURE,
  ADD_UPDATE_BULK_INCSERVE_REQUIREMENT,
  ADD_UPDATE_BULK_INCSERVE_REQUIREMENT_SUCCESS,
  ADD_UPDATE_BULK_INCSERVE_REQUIREMENT_FAILURE,
  UPDATE_BULK_INCEXIT_INTERVIEW,
  UPDATE_BULK_INCEXIT_INTERVIEW_SUCCESS,
  UPDATE_BULK_INCEXIT_INTERVIEW_FAILURE,
  MAPPED_INTERVIEWERS,
  MAPPED_INTERVIEWERS_SUCCESS,
  MAPPED_INTERVIEWERS_FAILURE,
  APPROVE_INCEXIT_FEEDBACK,
  APPROVE_INCEXIT_FEEDBACK_SUCCESS,
  APPROVE_INCEXIT_FEEDBACK_FAILURE,
  UPDATE_INTERVIEW_FEEDBACK_RATING_SUCCESS,
  UPDATE_INTERVIEW_FEEDBACK_RATING_FAILURE,
  UPDATE_INTERVIEW_FEEDBACK_RATING,
  RESPOND_RESCHEDULE_INCEXIT_INTERVIEW,
  RESPOND_RESCHEDULE_INCEXIT_INTERVIEW_SUCCESS,
  RESPOND_RESCHEDULE_INCEXIT_INTERVIEW_FAILURE,
  INCSERVE_INTERVIEWER_DETAILS,
  INCSERVE_INTERVIEWER_DETAILS_SUCCESS,
  INCSERVE_INTERVIEWER_DETAILS_FAILURE,
  INCSERVE_CALENDAR_INVITE,
  INCSERVE_CALENDAR_INVITE_SUCCESS,
  INCSERVE_CALENDAR_INVITE_FAILURE,
  INCEXIT_CALENDAR_INVITE,
  INCEXIT_CALENDAR_INVITE_SUCCESS,
  INCEXIT_CALENDAR_INVITE_FAILURE,
  INCSERVE_REQUEST_FOR_RESCHEDULED,
  INCSERVE_REQUEST_FOR_RESCHEDULED_SUCCESS,
  INCSERVE_REQUEST_FOR_RESCHEDULED_FAILURE,
  ADD_CUSTOM_FILE,
  ADD_CUSTOM_FILE_SUCCESS,
  ADD_CUSTOM_FILE_FAILURE,
  GET_INTERVIEWS_COUNT,
  GET_INTERVIEWS_COUNT_SUCCESS,
  GET_INTERVIEWS_COUNT_FAILURE,
  GET_INVOICE_DETAILS_BY_MONTH,
  GET_INVOICE_DETAILS_BY_MONTH_SUCCESS,
  GET_INVOICE_DETAILS_BY_MONTH_FAILURE,
  INTERVIEWER_ACCOUNT_DETAILS_LIST,
  INTERVIEWER_ACCOUNT_DETAILS_LIST_SUCCESS,
  INTERVIEWER_ACCOUNT_DETAILS_LIST_FAILURE,
  GET_INTERVIEWER_AUDIO_LOGS,
  GET_INTERVIEWER_AUDIO_LOGS_SUCCESS,
  GET_INTERVIEWER_AUDIO_LOGS_FAILURE,
  CREATE_EMPLOYER_POSITION,
  CREATE_EMPLOYER_POSITION_SUCCESS,
  CREATE_EMPLOYER_POSITION_FAILURE,
  INCSERVE_INCEXIT_PREVIOUSLY_ALLOTED_INTERVIEWER_LIST,
  INCSERVE_INCEXIT_PREVIOUSLY_ALLOTED_INTERVIEWER_LIST_SUCCESS,
  INCSERVE_INCEXIT_PREVIOUSLY_ALLOTED_INTERVIEWER_LIST_FAILURE,
  GET_CALL_LOGS,
  GET_CALL_LOGS_SUCCESS,
  GET_CALL_LOGS_FAILURE,
  UPDATE_ADD_QUESTION,
  UPDATE_ADD_QUESTION_SUCCESS,
  UPDATE_ADD_QUESTION_FAILURE,
  UPDATE_INCSERVE_ADD_QUESTION,
  UPDATE_INCSERVE_ADD_QUESTION_SUCCESS,
  UPDATE_INCSERVE_ADD_QUESTION_FAILURE,
  GET_INTERVIEWER_CALL_LOGS,
  GET_INTERVIEWER_CALL_LOGS_SUCCESS,
  GET_INTERVIEWER_CALL_LOGS_FAILURE,
  VERIFY_LINKEDIN_URL,
  VERIFY_LINKEDIN_URL_SUCCESS,
  VERIFY_LINKEDIN_URL_FAILURE,
  SEND_CONVERSION_REPORT,
  SEND_CONVERSION_REPORT_SUCCESS,
  SEND_CONVERSION_REPORT_FAILURE,
  GET_INCBOT_INTERVIEWS_SEARCH_LIST,
  GET_INCBOT_INTERVIEWS_SEARCH_LIST_SUCCESS,
  GET_INCBOT_INTERVIEWS_SEARCH_LIST_FAILURE,
  GET_INCBOT_POSITIONS,
  GET_INCBOT_POSITIONS_SUCCESS,
  GET_INCBOT_POSITIONS_FAILURE,
  GET_INCBOT_INTERVIEWS,
  GET_INCBOT_INTERVIEWS_SUCCESS,
  GET_INCBOT_INTERVIEWS_FAILURE,
  GET_ACTIVE_INCBOT_EMPLOYER,
  GET_ACTIVE_INCBOT_EMPLOYER_SUCCESS,
  GET_ACTIVE_INCBOT_EMPLOYER_FAILURE,
  INCSERVE_HOLD_INTERVIEW,
  INCSERVE_HOLD_INTERVIEW_SUCCESS,
  INCSERVE_HOLD_INTERVIEW_FAILURE,
  UPDATE_CANDIDATE_RATINGS,
  UPDATE_CANDIDATE_RATINGS_SUCCESS,
  UPDATE_CANDIDATE_RATINGS_FAILURE,
  PUT_INTERVIEWER_MOCK_REMARKS_INFO,
  PUT_INTERVIEWER_MOCK_REMARKS_INFO_SUCCESS,
  PUT_INTERVIEWER_MOCK_REMARKS_INFO_FAILURE,
  GET_API_CALL_LOGS,
  GET_API_CALL_LOGS_SUCCESS,
  GET_API_CALL_LOGS_FAILURE,
  UPDATE_API_CALL_LOGS,
  UPDATE_API_CALL_LOGS_SUCCESS,
  UPDATE_API_CALL_LOGS_FAILURE,
  GET_MEETING_DETAILS,
  GET_MEETING_DETAILS_SUCCESS,
  GET_MEETING_DETAILS_FAILURE,
  POST_MEETING_DETAILS,
  POST_MEETING_DETAILS_SUCCESS,
  POST_MEETING_DETAILS_FAILURE,
  GET_EVENTS_LIST,
  GET_EVENTS_LIST_SUCCESS,
  GET_EVENTS_LIST_FAILURE,
  FEEDBACK_APPROVAL,
  FEEDBACK_APPROVAL_SUCCESS,
  FEEDBACK_APPROVAL_FAILURE,
  UPDATE_FEEDBACK,
  UPDATE_FEEDBACK_SUCCESS,
  UPDATE_FEEDBACK_FAILURE,
  FETCH_RESUME_SUMMARY,
  FETCH_RESUME_SUMMARY_SUCCESS,
  FETCH_RESUME_SUMMARY_FAILURE,
  FETCH_TRANSCRIPT,
  FETCH_TRANSCRIPT_SUCCESS,
  FETCH_TRANSCRIPT_FAILURE,
  FETCH_AI_COMMENT,
  FETCH_AI_COMMENT_SUCCESS,
  FETCH_AI_COMMENT_FAILURE,
  FETCH_FINAL_REMARKS,
  FETCH_FINAL_REMARKS_SUCCESS,
  FETCH_FINAL_REMARKS_FAILURE,
  FETCH_AI_COMMENT_FOR_CODE,
  FETCH_AI_COMMENT_FOR_CODE_SUCCESS,
  FETCH_AI_COMMENT_FOR_CODE_FAILURE,
  SET_NETWORK_DISCONNECTED,
  SET_NETWORK_DISCONNECTED_SUCCESS,
  SET_NETWORK_DISCONNECTED_FAILURE,
  FEEDBACK_UN_APPROVAL,
  FEEDBACK_UN_APPROVAL_SUCCESS,
  FEEDBACK_UN_APPROVAL_FAILURE,
} from "../action/types";

const INIT_STATE = {
  loading: false,
  interviewList: null,
  feedbackReportData: null,
  positionList: null,
  predefinedPositionList: null,
  predefinedPositions: null,
  panelistDetails: [],
  notifyPanelist: null,
  verifyPanelist: null,
  shortlistedCandidates: null,
  interviewerList: null,
  dashboardStats: null,
  incservePositions: null,
  incserveRequirements: null,
  incserveAllInterviews: null,
  incserveInterview: null,
  IncservePosition: null,
  interviewerAccountDetails: null,
  interviewerBankNameList: null,
  interviewerUserInformation: null,
  timeStamp: null,
  interviewerAudioLogs: null,
  adminRemarksInfo: null,
  dropdownPositionList: null,
  employerInterviewDetails: null,
  getEmployerInterviewDetailsLoading: null,
  employerPositionDetails: null,
  getEmployerPositionDetailsLoading: null,
  incExitInterviewList: null,
  getIncExitInterviewLoading: null,
  AllInterviewers: null,
  previouslyAllotedInterviewers: null,
  designationList: null,
  incserveAllInterviewsSearchList: null,
  incbotAllInterviewsSearchList: null,
  ratings: null,
  similarWebsiteList: null,
  invoiceDetails: null,
  invoiceDetailsByMonth: null,
  incexitAllInterviewsSearchList: null,
  bulkInterviewList: null,
  incserveBulkDeleteRequirements: null,
  incserveSingleDeleteRequirements: null,
  bulkRequirementList: null,
  customFileDetails: null,
  incserveInterviewersDetails: null,
  interviewsCount: null,
  incserveIncexitpreviouslyAllotedInterviewers: null,
  calendarEvent: null,
  callLogs: null,
  interviewerCallLogs: null,
  incbotPositions: null,
  incbotInterviews: null,
  interviewerAccountList: null,
  activeIncbotEmployerList: null,
  interviewerMockRemarks: null,
  apiCallLogs: null,
  meetingDetails: null,
  countriesList: null,
  statesList: null,
  eventsList: null,
  feedbackData: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FEEDBACK_APPROVAL:
      return { ...state, loading: true };
    case FEEDBACK_APPROVAL_SUCCESS:
      return { ...state, loading: false };
    case FEEDBACK_APPROVAL_FAILURE:
      return { ...state, loading: false };
      case FEEDBACK_UN_APPROVAL:
      return { ...state, loading: true };
    case FEEDBACK_UN_APPROVAL_SUCCESS:
      return { ...state, loading: false };
    case FEEDBACK_UN_APPROVAL_FAILURE:
      return { ...state, loading: false };
    case GET_API_CALL_LOGS:
      return { ...state, loading: true };
    case GET_API_CALL_LOGS_SUCCESS:
      return { ...state, loading: false, apiCallLogs: action.payload };
    case GET_API_CALL_LOGS_FAILURE:
      return { ...state, loading: false };
    case UPDATE_API_CALL_LOGS:
      return { ...state, loading: true };
    case UPDATE_API_CALL_LOGS_SUCCESS:
      return { ...state, loading: false };
    case UPDATE_API_CALL_LOGS_FAILURE:
      return { ...state, loading: false };
    case GET_SIMILAR_WEBSITE_LIST:
    case UPDATE_CANDIDATE_RATINGS:
      return { ...state, loading: true };
    case UPDATE_CANDIDATE_RATINGS_SUCCESS:
      return { ...state, loading: false };
    case UPDATE_CANDIDATE_RATINGS_FAILURE:
      return { ...state, loading: false };
    case VERIFY_INTERVIEWER:
      return { ...state, loading: true };
    case VERIFY_INTERVIEWER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case VERIFY_INTERVIEWER_FAILURE:
      return { ...state, loading: false };
    case ALLOW_INTERVIEWER:
      return { ...state, loading: true };
    case ALLOW_INTERVIEWER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ALLOW_INTERVIEWER_FAILURE:
      return { ...state, loading: false };
    case CHANGE_PLATFORM:
      return { ...state, loading: true };
    case CHANGE_PLATFORM_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CHANGE_PLATFORM_FAILURE:
      return { ...state, loading: false };

    case UPDATE_INTERVIEW_FEEDBACK_RATING:
      return { ...state, loading: true };
    case UPDATE_INTERVIEW_FEEDBACK_RATING_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_INTERVIEW_FEEDBACK_RATING_FAILURE:
      return { ...state, loading: false };

    case CALL_INTERVIEWER_AND_CANDIDATE:
      return { ...state, loading: true };
    case CALL_INTERVIEWER_AND_CANDIDATE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CALL_INTERVIEWER_AND_CANDIDATE_FAILURE:
      return { ...state, loading: false };
    case CHANGE_INTERVIEWER_STATUS:
      return { ...state, loading: true };
    case CHANGE_INTERVIEWER_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CHANGE_INTERVIEWER_STATUS_FAILURE:
      return { ...state, loading: false };
    case RESEND_EMAIL_VERIFICATION:
      return { ...state, loading: true };
    case RESEND_EMAIL_VERIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case RESEND_EMAIL_VERIFICATION_FAILURE:
      return { ...state, loading: false };
    // INCSERVE POSITIONS
    case GET_INCSERVE_POSITIONS_BY_ID:
      return { ...state, loading: true };
    case GET_INCSERVE_POSITIONS_BY_ID_SUCCESS:
      return { ...state, loading: false, incserveInterview: action.payload };
    case GET_INCSERVE_POSITIONS_BY_ID_FAILURE:
      return { ...state, loading: false };
    case GET_SIMILAR_WEBSITE_LIST:
      return { ...state, loading: true };
    case GET_SIMILAR_WEBSITE_LIST_SUCCESS:
      return { ...state, loading: false, similarWebsiteList: action.payload };
    case GET_SIMILAR_WEBSITE_LIST_FAILURE:
      return { ...state, loading: false };
    case GET_INCSERVE_POSITIONS:
      return { ...state, loading: true };
    case GET_INCSERVE_POSITIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        timeStamp:
          state.timeStamp > action.payload.timeStamp
            ? state.timeStamp
            : action.payload.timeStamp,
        incservePositions:
          state.timeStamp > action.payload.timeStamp
            ? state.incservePositions
            : action.payload,
      };
    case GET_INCSERVE_POSITIONS_FAILURE:
      return { ...state, loading: false };
    case GET_INCBOT_POSITIONS:
      return { ...state, loading: true };
    case GET_INCBOT_POSITIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        incbotPositions: action.payload,
      };
    case GET_INCBOT_POSITIONS_FAILURE:
      return { ...state, loading: false };
    case GET_INCBOT_INTERVIEWS:
      return { ...state, loading: true };
    case GET_INCBOT_INTERVIEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        timeStamp:
          state.timeStamp > action.payload.timeStamp
            ? state.timeStamp
            : action.payload.timeStamp,
        incbotInterviews:
          state.timeStamp > action.payload.timeStamp
            ? state.incbotInterviews
            : action.payload,
      };
    case GET_INCBOT_INTERVIEWS_FAILURE:
      return { ...state, loading: false };
    case GET_ACTIVE_INCBOT_EMPLOYER:
      return { ...state, loading: true };
    case GET_ACTIVE_INCBOT_EMPLOYER_SUCCESS:
      return {
        ...state,
        loading: false,
        activeIncbotEmployerList: action.payload,
      };
    case GET_ACTIVE_INCBOT_EMPLOYER_FAILURE:
      return { ...state, loading: false };
    case GET_POSITIONS_DROPDOWN:
      return { ...state, loading: true };
    case GET_POSITIONS_DROPDOWN_SUCCESS:
      return { ...state, loading: false, dropdownPositionList: action.payload };
    case GET_POSITIONS_DROPDOWN_FAILURE:
      return { ...state, loading: false };
    case CREATE_OR_UPDATE_INCSERVE_POSITIONS:
      return { ...state, loading: true };
    case CREATE_OR_UPDATE_INCSERVE_POSITIONS_SUCCESS:
      return { ...state, loading: false };
    case CREATE_OR_UPDATE_INCSERVE_POSITIONS_FAILURE:
      return { ...state, loading: false };

    case MAPPED_INTERVIEWERS:
      return { ...state, loading: true };
    case MAPPED_INTERVIEWERS_SUCCESS:
      return { ...state, loading: false };
    case MAPPED_INTERVIEWERS_FAILURE:
      return { ...state, loading: false };

    case DELETE_INCSERVE_POSITIONS:
      return { ...state, loading: true };
    case DELETE_INCSERVE_POSITIONS_SUCCESS:
      return { ...state, loading: false };
    case DELETE_INCSERVE_POSITIONS_FAILURE:
      return { ...state, loading: false };

    case CREATE_EMPLOYER_POSITION:
      return { ...state, loading: true };
    case CREATE_EMPLOYER_POSITION_SUCCESS:
      return { ...state, loading: false };
    case CREATE_EMPLOYER_POSITION_FAILURE:
      return { ...state, loading: false };

    // INCSERVE REQUIREMENTS
    case GET_INCSERVE_REQUIREMENTS:
      return { ...state, loading: true };
    case GET_INCSERVE_REQUIREMENTS_SUCCESS:
      return { ...state, loading: false, incserveRequirements: action.payload };
    case GET_INCSERVE_REQUIREMENTS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case GET_INCSERVE_SINGLE_DELETE_LIST:
      return { ...state, loading: true };
    case GET_INCSERVE_SINGLE_DELETE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        incserveSingleDeleteRequirements: action.payload,
      };
    case GET_INCSERVE_SINGLE_DELETE_LIST_FAILURE:
    case GET_INCSERVE_BULK_DELETE_LIST:
      return { ...state, loading: true };
    case GET_INCSERVE_BULK_DELETE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        incserveBulkDeleteRequirements: action.payload,
      };
    case GET_INCSERVE_BULK_DELETE_LIST_FAILURE:
      return { ...state, loading: false };

    case INCSERVE_INTERVIEWER_DETAILS:
      return { ...state, loading: true };
    case INCSERVE_INTERVIEWER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        incserveInterviewersDetails: action.payload,
      };
    case INCSERVE_INTERVIEWER_DETAILS_FAILURE:
      return { ...state, loading: false };
    case INCSERVE_RESCHEDULED_ACCEPT_REQUEST:
      return { ...state, loading: true };
    case INCSERVE_RESCHEDULED_ACCEPT_REQUEST_SUCCESS:
      return { ...state, loading: false };
    case INCSERVE_RESCHEDULED_ACCEPT_REQUEST_FAILURE:
      return { ...state, loading: false };
    case INCSERVE_REQUEST_FOR_RESCHEDULED:
      return { ...state, loading: true };
    case INCSERVE_REQUEST_FOR_RESCHEDULED_SUCCESS:
      return { ...state, loading: false };
    case INCSERVE_REQUEST_FOR_RESCHEDULED_FAILURE:
      return { ...state, loading: false };
    case ADD_CUSTOM_FILE:
      return { ...state, loading: true };
    case ADD_CUSTOM_FILE_SUCCESS:
      return { ...state, loading: false, customFileDetails: action.payload };
    case ADD_CUSTOM_FILE_FAILURE:
      return { ...state, loading: false };
    case GET_INTERVIEWS_COUNT:
      return { ...state, loading: true };
    case GET_INTERVIEWS_COUNT_SUCCESS:
      return { ...state, loading: false, interviewsCount: action.payload };
    case GET_INTERVIEWS_COUNT_FAILURE:
      return { ...state, loading: false };
    // INCSERVE INTERVIEWS
    case GET_INCSERVE_INTERVIEWS:
      return { ...state, loading: true };
    case GET_INCSERVE_INTERVIEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        incserveAllInterviews: action.payload,
      };

    case GET_INCSERVE_INTERVIEWS_FAILURE:
      return { ...state, loading: false };

    case GET_INCSERVE_INTERVIEWS_SEARCH_LIST:
      return { ...state, loading: true };
    case GET_INCSERVE_INTERVIEWS_SEARCH_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        incserveAllInterviewsSearchList: action.payload,
      };
    case GET_INCSERVE_INTERVIEWS_SEARCH_LIST_FAILURE:
      return { ...state, loading: false };

    case GET_INCBOT_INTERVIEWS_SEARCH_LIST:
      return { ...state, loading: true };
    case GET_INCBOT_INTERVIEWS_SEARCH_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        timeStamp:
          state.timeStamp > action.payload.timeStamp
            ? state.timeStamp
            : action.payload.timeStamp,
        incbotAllInterviewsSearchList:
          state.timeStamp > action.payload.timeStamp
            ? state.incbotAllInterviewsSearchList
            : action.payload,
      };
    case GET_INCBOT_INTERVIEWS_SEARCH_LIST_FAILURE:
      return { ...state, loading: false };

    case GET_INCEXIT_INTERVIEWS_SEARCH_LIST:
      return { ...state, loading: true };
    case GET_INCEXIT_INTERVIEWS_SEARCH_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        incexitAllInterviewsSearchList: action.payload,
      };

    case GET_INCEXIT_INTERVIEWS_SEARCH_LIST_FAILURE:
      return { ...state, loading: false };
    case INCSERVE_APPROVE_FEEDBACK:
      return { ...state, loading: true };
    case INCSERVE_APPROVE_FEEDBACK_SUCCESS:
      return { ...state, loading: false };
    case INCSERVE_APPROVE_FEEDBACK_FAILURE:
      return { ...state, loading: false };
    case INCSERVE_GIVE_FEEDBACK:
      return { ...state, loading: true };
    case INCSERVE_GIVE_FEEDBACK_SUCCESS:
      return { ...state, loading: false };
    case INCSERVE_GIVE_FEEDBACK_FAILURE:
      return { ...state, loading: false };
    case INCSERVE_POST_REVIEW_REQUEST:
      return { ...state, loading: true };
    case INCSERVE_POST_REVIEW_REQUEST_SUCCESS:
      return { ...state, loading: false };
    case INCSERVE_POST_REVIEW_REQUEST_FAILURE:
      return { ...state, loading: false };
    case INCSERVE_CANCEL_INTERVIEW:
      return { ...state, loading: true };
    case INCSERVE_CANCEL_INTERVIEW_SUCCESS:
      return { ...state, loading: false };
    case INCSERVE_CANCEL_INTERVIEW_FAILURE:
      return { ...state, loading: false };
    case INCSERVE_HOLD_INTERVIEW:
      return { ...state, loading: true };
    case INCSERVE_HOLD_INTERVIEW_SUCCESS:
      return { ...state, loading: false };
    case INCSERVE_HOLD_INTERVIEW_FAILURE:
      return { ...state, loading: false };
    case INCSERVE_UPDATE_PAY_PER_INTERVIEW:
      return { ...state, loading: true };
    case INCSERVE_UPDATE_PAY_PER_INTERVIEW_SUCCESS:
      return { ...state, loading: false };
    case INCSERVE_UPDATE_PAY_PER_INTERVIEW_FAILURE:
      return { ...state, loading: false };
    case INCSERVE_ACCEPT_INTERVIEW:
      return { ...state, loading: true };
    case INCSERVE_ACCEPT_INTERVIEW_SUCCESS:
      return { ...state, loading: false };
    case INCSERVE_ACCEPT_INTERVIEW_FAILURE:
      return { ...state, loading: false };
    case ALLOT_AND_REALLOT_INTERVIEWER:
      return { ...state, loading: true };
    case ALLOT_AND_REALLOT_INTERVIEWER_SUCCESS:
      return { ...state, loading: false };
    case ALLOT_AND_REALLOT_INTERVIEWER_FAILURE:
      return { ...state, loading: false };

    case INCSERVE_RESCHEDULE_INTERVIEW:
      return { ...state, loading: true };
    case INCSERVE_RESCHEDULE_INTERVIEW_SUCCESS:
      return { ...state, loading: false };
    case INCSERVE_RESCHEDULE_INTERVIEW_FAILURE:
      return { ...state, loading: false };
    case GET_ALL_INTERVIEWERS:
      return { ...state, loading: true };
    case GET_ALL_INTERVIEWERS_SUCCESS:
      return {
        ...state,
        loading: false,
        AllInterviewers: action.payload,
      };
    case GET_ALL_INTERVIEWERS_FAILURE:
      return { ...state, loading: false };
    case GET_INCSERVE_INTERVIEWS_BY_ID:
      return { ...state, loading: true };
    case GET_INCSERVE_INTERVIEWS_BY_ID_SUCCESS:
      return { ...state, loading: false, incserveInterview: action.payload };
    case GET_INCSERVE_INTERVIEWS_BY_ID_FAILURE:
      return { ...state, loading: false };
    case CREATE_OR_UPDATE_INCSERVE_INTERVIEWS:
      return { ...state, loading: true };
    case CREATE_OR_UPDATE_INCSERVE_INTERVIEWS_SUCCESS:
      return { ...state, loading: false };
    case CREATE_OR_UPDATE_INCSERVE_INTERVIEWS_FAILURE:
      return { ...state, loading: false };
    //
    case GET_INTERVIEWER_RATINGS:
      return { ...state, loading: true };
    case GET_INTERVIEWER_RATINGS_SUCCESS:
      return { ...state, loading: false, ratings: action.payload };
    case GET_INTERVIEWER_RATINGS_FAILURE:
      return { ...state, loading: false };
    case GET_INTERVIEWER_ADMIN_REMARKS_INFO:
      return { ...state, loading: true };
    case GET_INTERVIEWER_ADMIN_REMARKS_INFO_SUCCESS:
      return { ...state, loading: false, adminRemarksInfo: action.payload };
    case GET_INTERVIEWER_ADMIN_REMARKS_INFO_FAILURE:
      return { ...state, loading: false };

    case PUT_INTERVIEWER_MOCK_REMARKS_INFO:
      return { ...state, loading: true };
    case PUT_INTERVIEWER_MOCK_REMARKS_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        interviewerMockRemarks: action.payload,
      };
    case PUT_INTERVIEWER_MOCK_REMARKS_INFO_FAILURE:
      return { ...state, loading: false };

    case GET_INTERVIEWER_AUDIO_LOGS:
      return { ...state, loading: true };
    case GET_INTERVIEWER_AUDIO_LOGS_SUCCESS:
      return { ...state, loading: false, interviewerAudioLogs: action.payload };
    case GET_INTERVIEWER_AUDIO_LOGS_FAILURE:
      return { ...state, loading: false };
    case UPDATE_INTERVIEWER_RATINGS:
      return { ...state, loading: true };
    case UPDATE_INTERVIEWER_RATINGS_SUCCESS:
      return { ...state, loading: false };
    case UPDATE_INTERVIEWER_RATINGS_FAILURE:
      return { ...state, loading: false };
    case UPDATE_INTERVIEWER_ADMIN_REMARKS_INFO:
      return { ...state, loading: true };
    case UPDATE_INTERVIEWER_ADMIN_REMARKS_INFO_SUCCESS:
      return { ...state, loading: false };
    case UPDATE_INTERVIEWER_ADMIN_REMARKS_INFO_FAILURE:
      return { ...state, loading: false };
    case UPDATE_INTERVIEWER_EDU_AND_SKILL_SET:
      return { ...state, loading: true };
    case UPDATE_INTERVIEWER_EDU_AND_SKILL_SET_SUCCESS:
      return { ...state, loading: false };
    case UPDATE_INTERVIEWER_EDU_AND_SKILL_SET_FAILURE:
      return { ...state, loading: false };
    case GET_INTERVIEWER_USER_INFORMATION:
      return { ...state, loading: true };
    case GET_INTERVIEWER_USER_INFORMATION_SUCCESS:
      return {
        ...state,
        loading: false,
        interviewerUserInformation: action.payload,
      };
    case GET_INTERVIEWER_USER_INFORMATION_FAILURE:
      return { ...state, loading: false };
    case UPDATE_INTERVIEWER_USER_INFORMATION:
      return { ...state, loading: true };
    case UPDATE_INTERVIEWER_USER_INFORMATION_SUCCESS:
      return { ...state, loading: false };
    case UPDATE_INTERVIEWER_USER_INFORMATION_FAILURE:
      return { ...state, loading: false };
    case GET_INTERVIEWER_BANK_NAME_LIST:
      return { ...state, loading: true };
    case GET_INTERVIEWER_BANK_NAME_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        interviewerBankNameList: action.payload,
      };
    case GET_INTERVIEWER_BANK_NAME_LIST_FAILURE:
      return { ...state, loading: false };
    case GET_INTERVIEWER_ACCOUNT_DETAILS:
      return { ...state, loading: true };
    case GET_INTERVIEWER_ACCOUNT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        interviewerAccountDetails: action.payload,
      };
    case GET_INTERVIEWER_ACCOUNT_DETAILS_FAILURE:
      return { ...state, loading: false };
    case ADD_OR_EDIT_INTERVIEWER_ACCOUNT_DETAILS:
      return { ...state, loading: true };
    case ADD_OR_EDIT_INTERVIEWER_ACCOUNT_DETAILS_SUCCESS:
      return { ...state, loading: false };
    case ADD_OR_EDIT_INTERVIEWER_ACCOUNT_DETAILS_FAILURE:
      return { ...state, loading: false };
    case GET_INTERVIEWER_LIST:
      return { ...state, loading: true };
    case GET_INTERVIEWER_LIST_SUCCESS:
      return { ...state, loading: false, interviewerList: action.payload };
    case GET_INTERVIEWER_LIST_FAILURE:
      return { ...state, loading: false };
    case GET_INVOICE_DETAILS:
      return { ...state, loading: true };
    case GET_INVOICE_DETAILS_SUCCESS:
      return { ...state, loading: false, invoiceDetails: action.payload };
    case GET_INVOICE_DETAILS_FAILURE:
      return { ...state, loading: false };

    case GET_INVOICE_DETAILS_BY_MONTH:
      return { ...state, loading: true };
    case GET_INVOICE_DETAILS_BY_MONTH_SUCCESS:
      return {
        ...state,
        loading: false,
        invoiceDetailsByMonth: action.payload,
      };
    case GET_INVOICE_DETAILS_BY_MONTH_FAILURE:
      return { ...state, loading: false };

    case BULK_INCSERVE_REQUIREMENT:
      return { ...state, loading: true };
    case BULK_INCSERVE_REQUIREMENT_SUCCESS:
      return {
        ...state,
        bulkRequirementList: action.payload,
        loading: false,
      };
    case BULK_INCSERVE_REQUIREMENT_FAILURE:
      return { ...state, loading: false };
    case ACCEPT_BULK_INCSERVE_REQUIREMENT:
      return { ...state, loading: true };
    case ACCEPT_BULK_INCSERVE_REQUIREMENT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ACCEPT_BULK_INCSERVE_REQUIREMENT_FAILURE:
      return { ...state, loading: false };

    case ADD_UPDATE_BULK_INCSERVE_REQUIREMENT:
      return { ...state, loading: true };
    case ADD_UPDATE_BULK_INCSERVE_REQUIREMENT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_UPDATE_BULK_INCSERVE_REQUIREMENT_FAILURE:
      return { ...state, loading: false };

    case CLOSE_BULK_INCSERVE_REQUIREMENT:
      return { ...state, loading: true };
    case CLOSE_BULK_INCSERVE_REQUIREMENT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CLOSE_BULK_INCSERVE_REQUIREMENT_FAILURE:
      return { ...state, loading: false };

    case DASHBOARD_STATS:
      return { ...state, loading: true };
    case DASHBOARD_STATS_SUCCESS:
      return { ...state, loading: false, dashboardStats: action.payload };
    case DASHBOARD_STATS_FAILURE:
      return { ...state, loading: false };
    case GET_INTERVIEW:
      return { ...state, loading: true };
    case GET_INTERVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        timeStamp:
          state.timeStamp > action.payload.timeStamp
            ? state.timeStamp
            : action.payload.timeStamp,
        interviewList:
          state.timeStamp > action.payload.timeStamp
            ? state.interviewList
            : action.payload,
      };
    case GET_INTERVIEW_FAILURE:
      return { ...state, loading: false };
    case GET_FEEDBACK_REPORT:
      return { ...state, loading: true };
    case GET_FEEDBACK_REPORT_SUCCESS:
      return { ...state, loading: false, feedbackReportData: action.payload };
    case GET_FEEDBACK_REPORT_FAILURE:
      return { ...state, loading: false };
    case CREATE_OR_UPDATE_POSITION:
      return { ...state, loading: true };
    case CREATE_OR_UPDATE_POSITION_SUCCESS:
      return { ...state, loading: false };
    case CREATE_OR_UPDATE_POSITION_FAILURE:
      return { ...state, loading: false };
    case GET_POSITION:
      return { ...state, loading: true };
    case GET_POSITION_SUCCESS:
      return {
        ...state,
        loading: false,
        positionList: action.payload,
      };
    case GET_POSITION_FAILURE:
      return { ...state, loading: false };
    case DELETE_POSITION:
      return { ...state, loading: true };
    case DELETE_POSITION_SUCCESS:
      return { ...state, loading: false };
    case DELETE_POSITION_FAILURE:
      return { ...state, loading: false };
    case GET_POSITION_LIST:
      return { ...state, loading: true };
    case GET_POSITION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        predefinedPositionList: action.payload,
      };
    case GET_POSITION_LIST_FAILURE:
      return { ...state, loading: false };
    case GET_PREDEFINED_POSITION_LIST:
      return { ...state, loading: true };
    case GET_PREDEFINED_POSITION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        predefinedPositions: action.payload,
      };
    case GET_PREDEFINED_POSITION_LIST_FAILURE:
      return { ...state, loading: false };
    case UPDATE_POSITION_LIST:
      return { ...state, loading: true };
    case UPDATE_POSITION_LIST_SUCCESS:
      return { ...state, loading: false };
    case UPDATE_POSITION_LIST_FAILURE:
      return { ...state, loading: false };
    case GET_PANELIST:
      return { ...state, loading: true };
    case GET_PANELIST_SUCCESS:
      return { ...state, panelistDetails: action.payload, loading: false };
    case GET_PANELIST_FAILURE:
      return { ...state, loading: false };
    case NOTIFY_PANELIST:
      return { ...state, loading: true };
    case NOTIFY_PANELIST_SUCCESS:
      return { ...state, loading: false, notifyPanelist: action.payload };
    case NOTIFY_PANELIST_FAILURE:
      return { ...state, loading: false };
    case UPDATE_PANELIST:
      return { ...state, loading: true };
    case UPDATE_PANELIST_SUCCESS:
      return { ...state, loading: false, updatePanelist: action.payload };
    case UPDATE_PANELIST_FAILURE:
      return { ...state, loading: false };
    case GET_EMPLOYER_INTERVIEW_DETAILS:
      return { ...state, getEmployerInterviewDetailsLoading: true };
    case GET_EMPLOYER_INTERVIEW_DETAILS_SUCCESS:
      return {
        ...state,
        getEmployerInterviewDetailsLoading: false,
        timeStamp:
          state.timeStamp > action.payload.timeStamp
            ? state.timeStamp
            : action.payload.timeStamp,
        employerInterviewDetails:
          state.timeStamp > action.payload.timeStamp
            ? state.employerInterviewDetails
            : action.payload,
      };
    case GET_EMPLOYER_INTERVIEW_DETAILS_FAILURE:
      return { ...state, getEmployerInterviewDetailsLoading: false };
    case GET_EMPLOYER_POSITION_DETAILS:
      return { ...state, getEmployerPositionDetailsLoading: true };
    case GET_EMPLOYER_POSITION_DETAILS_SUCCESS:
      return {
        ...state,
        getEmployerPositionDetailsLoading: false,
        timeStamp:
          state.timeStamp > action.payload.timeStamp
            ? state.timeStamp
            : action.payload.timeStamp,
        employerPositionDetails:
          state.timeStamp > action.payload.timeStamp
            ? state.employerPositionDetails
            : action.payload,
      };
    case GET_EMPLOYER_POSITION_DETAILS_FAILURE:
      return { ...state, getEmployerPositionDetailsLoading: false };
    case GET_INCEXIT_INTERVIEW:
      return {
        ...state,
        getIncExitInterviewLoading: true,
      };
    case GET_INCEXIT_INTERVIEW_SUCCESS:
      return {
        ...state,
        getIncExitInterviewLoading: false,
        incExitInterviewList: action.payload,
      };
    case GET_INCEXIT_INTERVIEW_FAILURE:
      return {
        ...state,
        getIncExitInterviewLoading: false,
      };
    case ACCEPT_INCEXIT_INTERVIEW:
      return { ...state, loading: true };
    case ACCEPT_INCEXIT_INTERVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ACCEPT_INCEXIT_INTERVIEW_FAILURE:
      return { ...state, loading: false };
    case PREVIOUSLY_ALLOTED_INTERVIEWER_LIST:
      return { ...state, loading: true };
    case PREVIOUSLY_ALLOTED_INTERVIEWER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        previouslyAllotedInterviewers: action.payload,
      };
    case PREVIOUSLY_ALLOTED_INTERVIEWER_LIST_FAILURE:
      return { ...state, loading: false };
    case INCSERVE_INCEXIT_PREVIOUSLY_ALLOTED_INTERVIEWER_LIST:
      return { ...state, loading: true };
    case INCSERVE_INCEXIT_PREVIOUSLY_ALLOTED_INTERVIEWER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        incserveIncexitpreviouslyAllotedInterviewers: action?.payload,
      };
    case INCSERVE_INCEXIT_PREVIOUSLY_ALLOTED_INTERVIEWER_LIST_FAILURE:
      return { ...state, loading: false };
    case DESIGNATION_LIST:
      return { ...state, loading: true };
    case DESIGNATION_LIST_SUCCESS:
      return { ...state, loading: false, designationList: action.payload };
    case DESIGNATION_LIST_FAILURE:
      return { ...state, loading: false };
    case UPDATE_INCEXIT_INTERVIEW:
      return { ...state, loading: true };
    case UPDATE_INCEXIT_INTERVIEW_SUCCESS:
      return { ...state, loading: false };
    case UPDATE_INCEXIT_INTERVIEW_FAILURE:
      return { ...state, loading: false };
    case CREATE_INCEXIT_INTERVIEW:
      return { ...state, loading: true };
    case CREATE_INCEXIT_INTERVIEW_SUCCESS:
      return { ...state, loading: false };
    case CREATE_INCEXIT_INTERVIEW_FAILURE:
      return { ...state, loading: false };
    case CANCEL_INCEXIT_INTERVIEW:
      return { ...state, loading: true };
    case CANCEL_INCEXIT_INTERVIEW_SUCCESS:
      return { ...state, loading: false };
    case CANCEL_INCEXIT_INTERVIEW_FAILURE:
      return { ...state, loading: false };
    case ALLOT_INCEXIT_INTERVIEWER:
      return { ...state, loading: true };
    case ALLOT_INCEXIT_INTERVIEWER_SUCCESS:
      return { ...state, loading: false };
    case ALLOT_INCEXIT_INTERVIEWER_FAILURE:
      return { ...state, loading: false };
    case SCHEDULE_INCEXIT_INTERVIEW:
      return { ...state, loading: true };
    case SCHEDULE_INCEXIT_INTERVIEW_SUCCESS:
      return { ...state, loading: false };
    case SCHEDULE_INCEXIT_INTERVIEW_FAILURE:
      return { ...state, loading: false };
    case RESCHEDULE_INCEXIT_INTERVIEW:
      return { ...state, loading: true };
    case RESCHEDULE_INCEXIT_INTERVIEW_SUCCESS:
      return { ...state, loading: false };
    case RESCHEDULE_INCEXIT_INTERVIEW_FAILURE:
      return { ...state, loading: false };
    case RESPOND_RESCHEDULE_INCEXIT_INTERVIEW:
      return { ...state, loading: true };
    case RESPOND_RESCHEDULE_INCEXIT_INTERVIEW_SUCCESS:
      return { ...state, loading: false };
    case RESPOND_RESCHEDULE_INCEXIT_INTERVIEW_FAILURE:
      return { ...state, loading: false };
    case BULK_INCEXIT_INTERVIEW:
      return { ...state, loading: true };
    case BULK_INCEXIT_INTERVIEW_SUCCESS:
      return {
        ...state,
        bulkInterviewList: action.payload,
        loading: false,
      };
    case BULK_INCEXIT_INTERVIEW_FAILURE:
      return { ...state, loading: false };

    case UPDATE_BULK_INCEXIT_INTERVIEW:
      return { ...state, loading: true };
    case UPDATE_BULK_INCEXIT_INTERVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_BULK_INCEXIT_INTERVIEW_FAILURE:
      return { ...state, loading: false };

    case ACCEPT_BULK_INCEXIT_INTERVIEW:
      return { ...state, loading: true };
    case ACCEPT_BULK_INCEXIT_INTERVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ACCEPT_BULK_INCEXIT_INTERVIEW_FAILURE:
      return { ...state, loading: false };
    case CLOSE_BULK_INCEXIT_INTERVIEW:
      return { ...state, loading: true };
    case CLOSE_BULK_INCEXIT_INTERVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CLOSE_BULK_INCEXIT_INTERVIEW_FAILURE:
      return { ...state, loading: false };
    case ADD_INCEXIT_FEEDBACK:
      return { ...state, loading: true };
    case ADD_INCEXIT_FEEDBACK_SUCCESS:
      return { ...state, loading: false };
    case ADD_INCEXIT_FEEDBACK_FAILURE:
      return { ...state, loading: false };
    case APPROVE_INCEXIT_FEEDBACK:
      return { ...state, loading: true };
    case APPROVE_INCEXIT_FEEDBACK_SUCCESS:
      return { ...state, loading: false };
    case APPROVE_INCEXIT_FEEDBACK_FAILURE:
      return { ...state, loading: false };
    case INCSERVE_CALENDAR_INVITE:
      return { ...state, loading: true };
    case INCSERVE_CALENDAR_INVITE_SUCCESS:
      return { ...state, loading: false };
    case INCSERVE_CALENDAR_INVITE_FAILURE:
      return { ...state, loading: false };
    case INCEXIT_CALENDAR_INVITE:
      return { ...state, loading: true };
    case INCEXIT_CALENDAR_INVITE_SUCCESS:
      return { ...state, loading: false };
    case INCEXIT_CALENDAR_INVITE_FAILURE:
      return { ...state, loading: false };
    case GET_CALENDAR_EVENT:
      return { ...state, loading: true };
    case GET_CALENDAR_EVENT_SUCCESS:
      return { ...state, loading: false, calendarEvent: action.payload };
    case GET_CALENDAR_EVENT_FAILURE:
      return { ...state, loading: false };
    case GET_CALL_LOGS:
      return { ...state, loading: true };
    case GET_CALL_LOGS_SUCCESS:
      return { ...state, loading: false, callLogs: action.payload };
    case GET_CALL_LOGS_FAILURE:
      return { ...state, loading: false };

    case GET_INTERVIEWER_CALL_LOGS:
      return { ...state, loading: true };
    case GET_INTERVIEWER_CALL_LOGS_SUCCESS:
      return { ...state, loading: false, interviewerCallLogs: action.payload };
    case GET_INTERVIEWER_CALL_LOGS_FAILURE:
      return { ...state, loading: false };
    case UPDATE_ADD_QUESTION:
      return { ...state, loading: true };
    case UPDATE_ADD_QUESTION_SUCCESS:
      return { ...state, loading: false };
    case UPDATE_ADD_QUESTION_FAILURE:
      return { ...state, loading: false };
    case VERIFY_LINKEDIN_URL:
      return { ...state, loading: true };
    case VERIFY_LINKEDIN_URL_SUCCESS:
      return { ...state, loading: false };
    case VERIFY_LINKEDIN_URL_FAILURE:
      return { ...state, loading: false };
    case SEND_CONVERSION_REPORT:
      return { ...state, loading: true };
    case SEND_CONVERSION_REPORT_SUCCESS:
      return { ...state, loading: false };
    case SEND_CONVERSION_REPORT_FAILURE:
      return { ...state, loading: false };
    case UPDATE_INCSERVE_ADD_QUESTION:
      return { ...state, loading: true };
    case UPDATE_INCSERVE_ADD_QUESTION_SUCCESS:
      return { ...state, loading: false };
    case UPDATE_INCSERVE_ADD_QUESTION_FAILURE:
      return { ...state, loading: false };
    case INTERVIEWER_ACCOUNT_DETAILS_LIST:
      return { ...state, loading: true };
    case INTERVIEWER_ACCOUNT_DETAILS_LIST_SUCCESS:
      // return {
      //   ...state,
      //   loading: false,
      //   interviewerAccountList: action.paylaod,
      // };
      return {
        ...state,
        loading: false,
        interviewerAccountList: action?.payload,
      };
    case INTERVIEWER_ACCOUNT_DETAILS_LIST_FAILURE:
      return { ...state, loading: false };

    case GET_MEETING_DETAILS:
      return { ...state, loading: true };
    case GET_MEETING_DETAILS_SUCCESS:
      return { ...state, loading: false, meetingDetails: action.payload };
    case GET_MEETING_DETAILS_FAILURE:
      return { ...state, loading: false };

    case POST_MEETING_DETAILS:
      return { ...state, loading: true };
    case POST_MEETING_DETAILS_SUCCESS:
      return { ...state, loading: false };
    case POST_MEETING_DETAILS_FAILURE:
      return { ...state, loading: false };

    case GET_EVENTS_LIST:
      return { ...state, loading: true };
    case GET_EVENTS_LIST_SUCCESS:
      return { ...state, loading: false, eventsList: action.payload };
    case GET_EVENTS_LIST_FAILURE:
      return { ...state, loading: false };

    case FETCH_RESUME_SUMMARY:
      return { ...state, loading: true };
    case FETCH_RESUME_SUMMARY_SUCCESS:
      return { ...state, loading: false };
    case FETCH_RESUME_SUMMARY_FAILURE:
      return { ...state, loading: false };

    case FETCH_TRANSCRIPT:
      return { ...state, loading: true };
    case FETCH_TRANSCRIPT_SUCCESS:
      return { ...state, loading: false };
    case FETCH_TRANSCRIPT_FAILURE:
      return { ...state, loading: false };

    case FETCH_AI_COMMENT:
      return { ...state, loading: true };
    case FETCH_AI_COMMENT_SUCCESS:
      return { ...state, loading: false };
    case FETCH_AI_COMMENT_FAILURE:
      return { ...state, loading: false };

    case FETCH_AI_COMMENT_FOR_CODE:
      return { ...state, loading: true };
    case FETCH_AI_COMMENT_FOR_CODE_SUCCESS:
      return { ...state, loading: false };
    case FETCH_AI_COMMENT_FOR_CODE_FAILURE:
      return { ...state, loading: false };

    case FETCH_FINAL_REMARKS:
      return { ...state, loading: true };
    case FETCH_FINAL_REMARKS_SUCCESS:
      return { ...state, loading: false };
    case FETCH_FINAL_REMARKS_FAILURE:
      return { ...state, loading: false };

    case UPDATE_FEEDBACK:
      return { ...state, loading: true };
    case UPDATE_FEEDBACK_SUCCESS:
      return { ...state, loading: false };
    case UPDATE_FEEDBACK_FAILURE:
      return { ...state, loading: false };

    case SET_NETWORK_DISCONNECTED:
      return { ...state, loading: true };
    case SET_NETWORK_DISCONNECTED_SUCCESS:
      return { ...state, loading: false };
    case SET_NETWORK_DISCONNECTED_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};

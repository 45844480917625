import React from "react";
import { all, takeEvery, put, call } from "redux-saga/effects";
import { toast } from "react-toastify";
import { Toast } from "../../utils/helper";
import {
  GET_CAREERS_LIST,
  GET_CAREERS_POSITION,
  CREATE_CAREERS_POSITION,
  UPDATE_CAREERS_POSITION,
  DELETE_CAREERS,
  DELETE_CAREERS_LIST,
  MOCK_GET_COUPON_CODE,
  MOCK_ADD_COUPON_CODE,
  MOCK_DELETE_COUPON_CODE,
  MOCK_GET_INTERVIEWS,
  MOCK_INTERVIEW_RESENT,
  MOCK_CANDIDATES,
  MOCK_SUBSCRIPTION,
  MOCK_CANDIDATE_UPDATE,
  MOCK_CANDIDATE_STATUS_UPDATE,
  MOCK_INTERVIEW_APPROVE,
  MOCK_INTERVIEW_NOT_APPROVE,
} from "../action/types";

import {
  getCareersListSuccess,
  getCareersListFailure,
  getCareersPositionSuccess,
  getCareersPositionFailure,
  createCareersPositionSuccess,
  createCareersPositionFailure,
  updateCareersPositionSuccess,
  updateCareersPositionFailure,
  deleteCareersSuccess,
  deleteCareersFailure,
  deleteCareersListSuccess,
  deleteCareersListFailure,
  mockSubscriptionListSuccess,
  mockSubscriptionListFailure,
  mockCandidatesListSuccess,
  mockCandidatesListFailure,
  mockInterviewResentSuccess,
  mockInterviewResentFailure,
  mockGetInterviewsSuccess,
  mockGetInterviewsFailure,
  mockDeleteCouponCodeSuccess,
  mockDeleteCouponCodeFailure,
  mockAddCouponCodeSuccess,
  mockAddCouponCodeFailure,
  mockGetCouponCodeSuccess,
  mockGetCouponCodeFailure,
  mockCandidateUpdateSuccess,
  mockCandidateUpdateFailure,
  mockCandidateStatusUpdateSuccess,
  mockCandidateStatusUpdateFailure,
  mockInterviewApproveSuccess,
  mockInterviewApproveFailure,
  mockInterviewNotApproveSuccess,
  mockInterviewNotApproveFailure,
} from "../action";
import CANDIDATE from "../../utils/api/candidate";

//CAREER
function* getCareersListRequest(action) {
  const isPageAndPageSize =
    action.payload.page && action.payload.pageSize
      ? `?page=${action.payload.page}&pageSize=${action.payload.pageSize}`
      : "";
  const isId = action?.payload?.id ? action?.payload?.id : "";
  const isSort = action?.payload?.sort ? `&sort=${action?.payload?.sort}` : "";
  const isSearch = action.payload.search
    ? `&search=${encodeURIComponent(action.payload.search)}`
    : "";
  try {
    const res = yield CANDIDATE.get(
      `admin/careers${isPageAndPageSize}&careerPosition=${isId}${isSearch}${isSort}`
    );
    if (res.status === 200) {
      yield put(getCareersListSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getCareersListFailure());
  }
}

function* getCareersPositionRequest(action) {
  const isFilter = action.payload.filters
    ? (action.payload.filters.from
        ? `&from=${action.payload.filters.from}T00:00:00`
        : "") +
      (action.payload.filters.to
        ? `&to=${action.payload.filters.to}T23:59:59`
        : "")
    : "";
  const isSort = action.payload.sort ? `&sort=${action.payload.sort}` : "";
  const isSearch = action.payload.search
    ? `&search=${encodeURIComponent(action.payload.search)}`
    : "";
  const isPageAndPageSize =
    action.payload.page && action.payload.pageSize
      ? `?page=${action.payload.page}&pageSize=${action.payload.pageSize}`
      : "";
  try {
    const res = yield CANDIDATE.get(
      `admin/careerPositions${isPageAndPageSize}${isFilter}${isSearch}${isSort}`
    );
    if (res.status === 200) {
      yield put(getCareersPositionSuccess(res.data.result));
    }
  } catch (e) {
    yield put(getCareersPositionFailure());
  }
}
function* createCareersPositionRequest(action) {
  try {
    const res = yield CANDIDATE.post(
      "admin/careerPositions",
      action.payload.data
    );
    if (res.status === 201 || res.status === 200) {
      yield put(createCareersPositionSuccess());
      yield call(action.payload.callback);
      yield toast.success(
        <Toast msg="Career position created successfully." />
      );
    }
  } catch (e) {
    const isT = '"responsibilities" is not allowed to be empty';
    yield put(createCareersPositionFailure());
    yield toast.error(
      <Toast
        msg={
          e?.response?.data?.message === isT
            ? "Job Description can't be empty"
            : e?.response?.data?.message
        }
      />
    );
  }
}
function* updateCareersPositionRequest(action) {
  try {
    const res = yield CANDIDATE.put(
      `admin/careerPositions/${action?.payload?.id}`,
      action.payload.data
    );
    if (res.status === 201 || res.status === 200) {
      yield put(updateCareersPositionSuccess());
      yield call(action.payload.callback);
      yield toast.success(
        <Toast msg="Career position updated successfully." />
      );
    }
  } catch (e) {
    yield put(updateCareersPositionFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* deleteCareersRequest(action) {
  try {
    const res = yield CANDIDATE.put(
      `admin/careerPositions/bulkDelete`,
      action.payload
    );
    if (res.status === 201 || res.status === 200) {
      yield put(deleteCareersSuccess());
      yield call(action.payload.callback);
      yield toast.success(
        <Toast msg="Career position deleted successfully." />
      );
    }
  } catch (e) {
    yield put(deleteCareersFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* deleteCareersListRequest(action) {
  try {
    const res = yield CANDIDATE.put(`admin/careers/bulkDelete`, action.payload);
    if (res.status === 201 || res.status === 200) {
      yield put(deleteCareersListSuccess());
      yield call(action.payload.callback);
      yield toast.success(
        <Toast msg="Career position deleted successfully." />
      );
    }
  } catch (e) {
    yield put(deleteCareersListFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* mockGetCouponCodeRequest(action) {
  try {
    const { search, pageSize, page, sort } = action.payload;
    const isSearch = search ? `&search=${encodeURIComponent(search)}` : "";
    const isPageAndPageSize =
      page && pageSize ? `?page=${page}&pageSize=${pageSize}` : "";
    const isSort = sort ? `&sort=${sort}` : "";
    const res = yield CANDIDATE.get(
      `mock-interview/admin/getCouponCodes${isPageAndPageSize}${isSearch}${isSort}`
    );
    if (res.status === 201 || res.status === 200) {
      yield put(mockGetCouponCodeSuccess(res.data.result));
    }
  } catch (e) {
    yield put(mockGetCouponCodeFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* mockAddCouponCodeRequest(action) {
  try {
    const res = yield CANDIDATE.post(
      `mock-interview/admin/createAndUpdateCoupon`,
      action.payload?.data
    );
    if (res.status === 201 || res.status === 200) {
      yield put(mockAddCouponCodeSuccess());
      yield toast.success(
        <Toast
          msg={
            action?.payload?.couponId
              ? "Coupon code updated successfully."
              : "Coupon code created successfully."
          }
        />
      );
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put(mockAddCouponCodeFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* mockDeleteCouponCodeRequest(action) {
  try {
    const res = yield CANDIDATE.delete(
      `mock-interview/admin/coupon/${action?.payload?.couponId}`
    );
    if ([200, 201, 204].includes(res.status)) {
      yield put(mockDeleteCouponCodeSuccess());
      yield toast.success(<Toast msg="Coupon code deleted successfully." />);
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put(mockDeleteCouponCodeFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* mockGetInterviewsRequest(action) {
  try {
    const { search, pageSize, page, sort } = action.payload;
    const isSearch = search ? `&search=${encodeURIComponent(search)}` : "";
    const isPageAndPageSize =
      page && pageSize ? `?page=${page}&pageSize=${pageSize}` : "";
    const isSort = sort ? `&sort=${sort}` : "";
    const res = yield CANDIDATE.get(
      `mock-interview/admin/getCandidateInterviews${isPageAndPageSize}${isSearch}${isSort}`
    );
    if (res.status === 201 || res.status === 200) {
      yield put(mockGetInterviewsSuccess(res.data.result));
    }
  } catch (e) {
    yield put(mockGetInterviewsFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* mockInterviewResentRequest(action) {
  try {
    const res = yield CANDIDATE.get(
      `mock-interview/admin/re-invite/${action?.payload?.interviewId}`
    );
    if (res.status === 201 || res.status === 200) {
      yield put(mockInterviewResentSuccess());
      yield toast.success(<Toast msg="Interview invite sent successfully." />);
    }
  } catch (e) {
    yield put(mockInterviewResentFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* mockCandidatesListRequest(action) {
  try {
    const { search, pageSize, page } = action.payload;
    const isSearch = search ? `&search=${encodeURIComponent(search)}` : "";
    const isPageAndPageSize =
      page && pageSize ? `?page=${page}&pageSize=${pageSize}` : "";
    const res = yield CANDIDATE.get(
      `mock-interview/admin/getCandidates${isPageAndPageSize}${isSearch}`
    );
    if (res.status === 201 || res.status === 200) {
      yield put(mockCandidatesListSuccess(res.data.result));
    }
  } catch (e) {
    yield put(mockCandidatesListFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* mockSubscriptionListRequest(action) {
  try {
    const res = yield CANDIDATE.get(
      `mock-interview/admin/purchaseHistory/${action?.payload}`
    );
    if (res.status === 201 || res.status === 200) {
      yield put(mockSubscriptionListSuccess(res.data.result));
    }
  } catch (e) {
    yield put(mockSubscriptionListFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* mockCandidateUpdateRequest(action) {
  try {
    const formData = new FormData();
    const {
      fullName,
      whatsappNumber,
      orgName,
      experience,
      resume,
      photo,
      autoApprove,
    } = action.payload?.data;
    formData.append("fullName", fullName);
    formData.append("whatsappNumber", whatsappNumber);
    if (orgName) formData.append("orgName", orgName);
    if (experience) formData.append("experience", experience);
    if (resume) formData.append("resume", resume);
    if (photo) formData.append("photo", photo);
    formData.append("autoApprove", autoApprove || false);
    const res = yield CANDIDATE.post(
      `mock-interview/admin/updateCandidate/${action?.payload?.candidateId}`,
      formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
    if (res.status === 201 || res.status === 200) {
      yield put(mockCandidateUpdateSuccess(res.data.result));
      yield toast.success(<Toast msg="Candidate updated successfully." />);
    }
  } catch (e) {
    yield put(mockCandidateUpdateFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}
function* mockCandidateStatusUpdateRequest(action) {
  try {
    const res = yield CANDIDATE.put(
      `mock-interview/admin/candidate/${action?.payload?.candidateId}/${action?.payload?.status}`
    );
    if (res.status === 201 || res.status === 200) {
      yield put(mockCandidateStatusUpdateSuccess(res.data.result));
      yield toast.success(
        <Toast
          msg={`Candidate ${action?.payload?.status?.toLowerCase()} successfully.`}
        />
      );
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put(mockCandidateStatusUpdateFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* mockInterviewApproveRequest(action) {
  try {
    const res = yield CANDIDATE.put(
      `mock-interview/admin/interviewApprove/${action?.payload?.interviewId}`
    );
    if (res.status === 201 || res.status === 200) {
      yield put(mockInterviewApproveSuccess());
      yield toast.success(<Toast msg="Interview Approved Successfully." />);
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put(mockInterviewApproveFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

function* mockInterviewNotApproveRequest(action) {
  try {
    const res = yield CANDIDATE.put(
      `mock-interview/admin/interviewUnApprove/${action?.payload?.interviewId}`
    );
    if (res.status === 201 || res.status === 200) {
      yield put(mockInterviewNotApproveSuccess());
      yield toast.success(<Toast msg="Interview Unapproved Successfully." />);
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put(mockInterviewNotApproveFailure());
    yield toast.error(<Toast msg={e?.response?.data?.message} />);
  }
}

export function* watchMockInterviewApproveAPI() {
  yield takeEvery(MOCK_INTERVIEW_APPROVE, mockInterviewApproveRequest);
}
export function* watchMockInterviewNotApproveAPI() {
  yield takeEvery(MOCK_INTERVIEW_NOT_APPROVE, mockInterviewNotApproveRequest);
}
export function* watchGetCareersAPI() {
  yield takeEvery(GET_CAREERS_LIST, getCareersListRequest);
}
export function* watchGetCareersPositionAPI() {
  yield takeEvery(GET_CAREERS_POSITION, getCareersPositionRequest);
}
export function* watchCreateCareersPositionAPI() {
  yield takeEvery(CREATE_CAREERS_POSITION, createCareersPositionRequest);
}
export function* watchUpdateCareersPositionAPI() {
  yield takeEvery(UPDATE_CAREERS_POSITION, updateCareersPositionRequest);
}
export function* watchDeleteCareersAPI() {
  yield takeEvery(DELETE_CAREERS, deleteCareersRequest);
}
export function* watchDeleteCareersListAPI() {
  yield takeEvery(DELETE_CAREERS_LIST, deleteCareersListRequest);
}
export function* watchMockSubscriptionListAPI() {
  yield takeEvery(MOCK_SUBSCRIPTION, mockSubscriptionListRequest);
}
export function* watchMockCandidatesListAPI() {
  yield takeEvery(MOCK_CANDIDATES, mockCandidatesListRequest);
}
export function* watchMockInterviewResentAPI() {
  yield takeEvery(MOCK_INTERVIEW_RESENT, mockInterviewResentRequest);
}
export function* watchMockGetInterviewsAPI() {
  yield takeEvery(MOCK_GET_INTERVIEWS, mockGetInterviewsRequest);
}
export function* watchMockDeleteCouponCodeAPI() {
  yield takeEvery(MOCK_DELETE_COUPON_CODE, mockDeleteCouponCodeRequest);
}
export function* watchMockAddCouponCodeAPI() {
  yield takeEvery(MOCK_ADD_COUPON_CODE, mockAddCouponCodeRequest);
}
export function* watchMockGetCouponCodeAPI() {
  yield takeEvery(MOCK_GET_COUPON_CODE, mockGetCouponCodeRequest);
}
export function* watchMockCandidateUpdateAPI() {
  yield takeEvery(MOCK_CANDIDATE_UPDATE, mockCandidateUpdateRequest);
}
export function* watchMockCandidateStatusUpdateAPI() {
  yield takeEvery(
    MOCK_CANDIDATE_STATUS_UPDATE,
    mockCandidateStatusUpdateRequest
  );
}
export default function* rootSaga() {
  yield all([
    watchGetCareersAPI(),
    watchGetCareersPositionAPI(),
    watchCreateCareersPositionAPI(),
    watchUpdateCareersPositionAPI(),
    watchDeleteCareersAPI(),
    watchDeleteCareersListAPI(),
    watchMockSubscriptionListAPI(),
    watchMockCandidatesListAPI(),
    watchMockInterviewResentAPI(),
    watchMockGetInterviewsAPI(),
    watchMockDeleteCouponCodeAPI(),
    watchMockAddCouponCodeAPI(),
    watchMockGetCouponCodeAPI(),
    watchMockCandidateUpdateAPI(),
    watchMockCandidateStatusUpdateAPI(),
    watchMockInterviewApproveAPI(),
    watchMockInterviewNotApproveAPI(),
  ]);
}

import {
  GET_CAREERS_LIST,
  GET_CAREERS_LIST_SUCCESS,
  GET_CAREERS_LIST_FAILURE,
  GET_CAREERS_POSITION,
  GET_CAREERS_POSITION_SUCCESS,
  GET_CAREERS_POSITION_FAILURE,
  CREATE_CAREERS_POSITION,
  CREATE_CAREERS_POSITION_SUCCESS,
  CREATE_CAREERS_POSITION_FAILURE,
  UPDATE_CAREERS_POSITION,
  UPDATE_CAREERS_POSITION_SUCCESS,
  UPDATE_CAREERS_POSITION_FAILURE,
  DELETE_CAREERS,
  DELETE_CAREERS_SUCCESS,
  DELETE_CAREERS_FAILURE,
  DELETE_CAREERS_LIST,
  DELETE_CAREERS_LIST_SUCCESS,
  DELETE_CAREERS_LIST_FAILURE,
  MOCK_GET_COUPON_CODE,
  MOCK_GET_COUPON_CODE_SUCCESS,
  MOCK_GET_COUPON_CODE_FAILURE,
  MOCK_ADD_COUPON_CODE,
  MOCK_ADD_COUPON_CODE_SUCCESS,
  MOCK_ADD_COUPON_CODE_FAILURE,
  MOCK_DELETE_COUPON_CODE,
  MOCK_DELETE_COUPON_CODE_SUCCESS,
  MOCK_DELETE_COUPON_CODE_FAILURE,
  MOCK_GET_INTERVIEWS,
  MOCK_GET_INTERVIEWS_SUCCESS,
  MOCK_GET_INTERVIEWS_FAILURE,
  MOCK_INTERVIEW_RESENT,
  MOCK_INTERVIEW_RESENT_SUCCESS,
  MOCK_INTERVIEW_RESENT_FAILURE,
  MOCK_CANDIDATES,
  MOCK_CANDIDATES_SUCCESS,
  MOCK_CANDIDATES_FAILURE,
  MOCK_SUBSCRIPTION,
  MOCK_SUBSCRIPTION_SUCCESS,
  MOCK_SUBSCRIPTION_FAILURE,
  MOCK_CANDIDATE_UPDATE,
  MOCK_CANDIDATE_UPDATE_SUCCESS,
  MOCK_CANDIDATE_UPDATE_FAILURE,
  MOCK_CANDIDATE_STATUS_UPDATE,
  MOCK_CANDIDATE_STATUS_UPDATE_SUCCESS,
  MOCK_CANDIDATE_STATUS_UPDATE_FAILURE,
  MOCK_INTERVIEW_APPROVE,
  MOCK_INTERVIEW_APPROVE_SUCCESS,
  MOCK_INTERVIEW_APPROVE_FAILURE,
  MOCK_INTERVIEW_NOT_APPROVE,
  MOCK_INTERVIEW_NOT_APPROVE_SUCCESS,
  MOCK_INTERVIEW_NOT_APPROVE_FAILURE,
} from "./types";

// CAREER
export const getCareersList = (payload) => ({
  type: GET_CAREERS_LIST,
  payload,
});
export const getCareersListSuccess = (payload) => ({
  type: GET_CAREERS_LIST_SUCCESS,
  payload,
});
export const getCareersListFailure = () => ({
  type: GET_CAREERS_LIST_FAILURE,
});
export const getCareersPosition = (payload) => ({
  type: GET_CAREERS_POSITION,
  payload,
});
export const getCareersPositionSuccess = (payload) => ({
  type: GET_CAREERS_POSITION_SUCCESS,
  payload,
});
export const getCareersPositionFailure = () => ({
  type: GET_CAREERS_POSITION_FAILURE,
});
export const createCareersPosition = (payload) => ({
  type: CREATE_CAREERS_POSITION,
  payload,
});
export const createCareersPositionSuccess = () => ({
  type: CREATE_CAREERS_POSITION_SUCCESS,
});
export const createCareersPositionFailure = () => ({
  type: CREATE_CAREERS_POSITION_FAILURE,
});
export const updateCareersPosition = (payload) => ({
  type: UPDATE_CAREERS_POSITION,
  payload,
});
export const updateCareersPositionSuccess = () => ({
  type: UPDATE_CAREERS_POSITION_SUCCESS,
});
export const updateCareersPositionFailure = () => ({
  type: UPDATE_CAREERS_POSITION_FAILURE,
});
export const deleteCareers = (payload) => ({
  type: DELETE_CAREERS,
  payload,
});
export const deleteCareersSuccess = () => ({
  type: DELETE_CAREERS_SUCCESS,
});
export const deleteCareersFailure = () => ({
  type: DELETE_CAREERS_FAILURE,
});
export const deleteCareersList = (payload) => ({
  type: DELETE_CAREERS_LIST,
  payload,
});
export const deleteCareersListSuccess = () => ({
  type: DELETE_CAREERS_LIST_SUCCESS,
});
export const deleteCareersListFailure = () => ({
  type: DELETE_CAREERS_LIST_FAILURE,
});
export const mockSubscriptionList = (payload) => ({
  type: MOCK_SUBSCRIPTION,
  payload,
});
export const mockSubscriptionListSuccess = (payload) => ({
  type: MOCK_SUBSCRIPTION_SUCCESS,
  payload,
});
export const mockSubscriptionListFailure = () => ({
  type: MOCK_SUBSCRIPTION_FAILURE,
});
export const mockCandidatesList = (payload) => ({
  type: MOCK_CANDIDATES,
  payload,
});
export const mockCandidatesListSuccess = (payload) => ({
  type: MOCK_CANDIDATES_SUCCESS,
  payload,
});
export const mockCandidatesListFailure = () => ({
  type: MOCK_CANDIDATES_FAILURE,
});
export const mockInterviewResent = (payload) => ({
  type: MOCK_INTERVIEW_RESENT,
  payload,
});
export const mockInterviewResentSuccess = () => ({
  type: MOCK_INTERVIEW_RESENT_SUCCESS,
});
export const mockInterviewResentFailure = () => ({
  type: MOCK_INTERVIEW_RESENT_FAILURE,
});
export const mockGetInterviews = (payload) => ({
  type: MOCK_GET_INTERVIEWS,
  payload,
});
export const mockGetInterviewsSuccess = (payload) => ({
  type: MOCK_GET_INTERVIEWS_SUCCESS,
  payload,
});
export const mockGetInterviewsFailure = () => ({
  type: MOCK_GET_INTERVIEWS_FAILURE,
});
export const mockDeleteCouponCode = (payload) => ({
  type: MOCK_DELETE_COUPON_CODE,
  payload,
});
export const mockDeleteCouponCodeSuccess = () => ({
  type: MOCK_DELETE_COUPON_CODE_SUCCESS,
});
export const mockDeleteCouponCodeFailure = () => ({
  type: MOCK_DELETE_COUPON_CODE_FAILURE,
});
export const mockAddCouponCode = (payload) => ({
  type: MOCK_ADD_COUPON_CODE,
  payload,
});
export const mockAddCouponCodeSuccess = () => ({
  type: MOCK_ADD_COUPON_CODE_SUCCESS,
});
export const mockAddCouponCodeFailure = () => ({
  type: MOCK_ADD_COUPON_CODE_FAILURE,
});
export const mockGetCouponCode = (payload) => ({
  type: MOCK_GET_COUPON_CODE,
  payload,
});
export const mockGetCouponCodeSuccess = (payload) => ({
  type: MOCK_GET_COUPON_CODE_SUCCESS,
  payload,
});
export const mockGetCouponCodeFailure = () => ({
  type: MOCK_GET_COUPON_CODE_FAILURE,
});
export const mockCandidateUpdate = (payload) => ({
  type: MOCK_CANDIDATE_UPDATE,
  payload,
});
export const mockCandidateUpdateSuccess = () => ({
  type: MOCK_CANDIDATE_UPDATE_SUCCESS,
});
export const mockCandidateUpdateFailure = () => ({
  type: MOCK_CANDIDATE_UPDATE_FAILURE,
});
export const mockCandidateStatusUpdate = (payload) => ({
  type: MOCK_CANDIDATE_STATUS_UPDATE,
  payload,
});
export const mockCandidateStatusUpdateSuccess = () => ({
  type: MOCK_CANDIDATE_STATUS_UPDATE_SUCCESS,
});
export const mockCandidateStatusUpdateFailure = () => ({
  type: MOCK_CANDIDATE_STATUS_UPDATE_FAILURE,
});

export const mockInterviewApprove = (payload) => ({
  type: MOCK_INTERVIEW_APPROVE,
  payload,
});
export const mockInterviewApproveSuccess = () => ({
  type: MOCK_INTERVIEW_APPROVE_SUCCESS,
});
export const mockInterviewApproveFailure = () => ({
  type: MOCK_INTERVIEW_APPROVE_FAILURE,
});

export const mockInterviewNotApprove = (payload) => ({
  type: MOCK_INTERVIEW_NOT_APPROVE,
  payload,
});
export const mockInterviewNotApproveSuccess = () => ({
  type: MOCK_INTERVIEW_NOT_APPROVE_SUCCESS,
});
export const mockInterviewNotApproveFailure = () => ({
  type: MOCK_INTERVIEW_NOT_APPROVE_FAILURE,
});

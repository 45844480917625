import { memo } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const ContactInput = memo(
  ({
    disabled,
    name,
    label,
    labelClass,
    isRequired,
    formData,
    error,
    setNumber,
    errorClass,
    placeholder,
  }) => {
    const handleCountryCode = (value, country, e, formattedValue) => {
      let number = value;
      let code = country.dialCode;
      let newNumber = number.slice(code.length, number.length);
      setNumber(newNumber, code);
    };
    return (
      <>
        <label className={labelClass}>
          {label}{" "}
          {isRequired && (
            <span className="asterisk f-12 text-theme-red">*</span>
          )}
        </label>
        <PhoneInput
          disabled={disabled}
          name="phone"
          country="in"
          countryCodeEditable={false}
          value={formData}
          inputProps={{
            name: "phone",
          }}
          onChange={(value, country, e, formattedValue) =>
            handleCountryCode(value, country, e, formattedValue)
          }
          containerClass={`f-l f-med rounded ${error ? "error" : ""}`}
          inputClass="w-100 f-12"
          dropdownClass="rounded"
          placeholder={placeholder}
        />
        {error && <div className={`invalid ${errorClass}`}>{error}</div>}
      </>
    );
  }
);
export default ContactInput;

import React, { useEffect, useLayoutEffect, useState } from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { fileExtension, fileType } from "./constant";
import qs from "qs";
import CryptoJS from "crypto-js";
import { productMenus } from "./constant";
import axios from "axios";
import { PURGE } from "redux-persist";
import { persistor } from "../redux/store";
import dashboardLogo from "../assets/admin Images/sidebar/dashboard-logo.svg";
import employerManage from "../assets/admin Images/sidebar/employer-manage-logo.svg";
import productManage from "../assets/admin Images/sidebar/product-manage-logo.svg";
import roleSubuser from "../assets/admin Images/sidebar/role-subuser-logo.svg";
import positionsManage from "../assets/admin Images/sidebar/positions-manage-logo.svg";
import contactInquiries from "../assets/admin Images/sidebar/contact-logo.svg";
import announcements from "../assets/admin Images/sidebar/announcement-logo.svg";
import career from "../assets/admin Images/sidebar/career-logo.svg";
import support from "../assets/admin Images/sidebar/support-logo.svg";
import referEarn from "../assets/admin Images/sidebar/refer-earn-logo.svg";
import questionBank from "../assets/admin Images/sidebar/question-bank-logo.svg";
import locations from "../assets/admin Images/sidebar/locations.svg";
import faqImage from "../assets/admin Images/sidebar/faq-logo.svg";
import newsarticle from "../assets/admin Images/sidebar/news-article.svg";
import apiCallLogs from "../assets/admin Images/sidebar/api-call-logs.svg";
import mockInterviewIcon from "../assets/admin Images/sidebar/mockInterviewIcon.svg";
import location from "../assets/admin Images/sidebar/location.svg";
import twoFactor from "../assets/admin Images/sidebar/two-factor.svg";
import eventTracking from "../assets/admin Images/sidebar/event-tracking.svg";
import meeting from "../assets/admin Images/sidebar/metting.svg";
import panelist from "../assets/admin Images/sidebar/panelist-logo.svg";
import interview from "../assets/admin Images/sidebar/interview-logo.svg";
import incserveAdminLogo from "../assets/admin Images/dashboard/incserve-admin-logo.svg";
import incexitAdminLogo from "../assets/admin Images/dashboard/incexit-admin-logo.svg";
import search_icon from "../assets/images/search_icon.svg";
import moment from "moment";
import { toast } from "react-toastify";

export const secretKey = "democlient:democlientsecret";
export const encryptKey = "y347t567t347InCrUiTeRb6t5b3487y5";
export const isSpecialChar = (value) =>
  /[!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]+/.test(value);

export const isUpperCase = (value) => /[A-Z]/.test(value);
export const isLowerCase = (value) => /[a-z]/.test(value);
export const isContainNumber = (value) => /\d/.test(value);
export const isValidUrl = (urlString) => {
  var urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // validate protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // validate fragment locator
  return !!urlPattern.test(urlString);
};
export const localStorageName = [
  { key: "token", value: "iCtK" },
  { key: "refreshToken", value: "iCrT" },
  { key: "incserveToken", value: "iCiT" },
  { key: "incserveTokenKey", value: "iCiTi" },
  { key: "userData", value: "iCuD" },
  { key: "companyData", value: "iCcD" },
  { key: "productId", value: "iCpD" },
  { key: "credit", value: "iCcR" },
  { key: "expiryTime", value: "iCeT" },
  { key: "permissions", value: "ic_p" },
  { key: "feedbackData", value: "feedDat" },
  { key: "role", value: "ic_r" },
  { key: "feedbackList", value: "fbl" },
];

export const getToken = () => localStorageJsonParseDecrypt("token");
export const removeToken = () => localStorageRemove("token");

export const FeedbackSystemType = (type) => {
  if (type === "manual") {
    return "Manual";
  } else if (type === "automation") {
    return "Automation";
  }
};

export const statusClass = (status) => {
  let tab = 4;
  if (status) {
    switch (status.toLowerCase()) {
      case "pending":
        return {
          className: "status-pending",
          value: "Pending",
          gridClass: "status-type-1",
          cardClass: "status-sticker-type-1",
        };
      case "completed":
        return {
          className: "status-completed",
          value: "Completed",
          gridClass: "status-type-3",
          cardClass: "status-sticker-type-1",
        };
      case "failed":
        return {
          className: "status-rejected",
          value: "Failed",
          gridClass: "status-type-5",
          cardClass: "status-sticker-type-1",
        };
      case "busy":
        return {
          className: "status-shortlisted",
          value: "Busy",
          gridClass: "status-type-2",
          cardClass: "status-sticker-type-1",
        };
      case "no-answer":
        return {
          className: "status-shortlisted",
          value: "No answer",
          gridClass: "status-type-1",
          cardClass: "status-sticker-type-1",
        };
      case "started":
        return {
          className: "status-shortlisted",
          value: "Started",
          gridClass: "status-type-7",
          cardClass: "status-sticker-type-1",
        };
      case "accepted":
        return {
          className: "status-accepted",
          value: "Accepted",
          gridClass: "status-type-2",
          cardClass: "status-sticker-type-2",
        };
      case "scheduled":
        return {
          className: "status-scheduled",
          value: "Scheduled",
          gridClass: "status-type-6",
          cardClass: "status-sticker-type-6",
        };
      case "rescheduled":
        return {
          className: "status-scheduled",
          value: "Scheduled",
          gridClass: "status-type-6",
          cardClass: "status-sticker-type-6",
        };
      case "shortlisted":
        if (tab === 1) {
          return {
            className: "status-shortlisted",
            value: "Completed",
            gridClass: "status-type-3",
            cardClass: "status-sticker-type-3",
          };
        } else {
          return {
            className: "status-shortlisted",
            value: "Shortlisted",
            gridClass: "status-type-3",
            cardClass: "status-sticker-type-3",
          };
        }
      case "hold":
        if (tab === 1) {
          return {
            className: "status-shortlisted",
            value: "Completed",
            gridClass: "status-type-3",
            cardClass: "status-sticker-type-3",
          };
        } else {
          return {
            className: "status-hold",
            value: "On Hold",
            gridClass: "status-type-4",
            cardClass: "status-sticker-type-4",
          };
        }
      case "rejected":
        if (tab === 1) {
          return {
            className: "status-shortlisted",
            value: "Completed",
            gridClass: "status-type-3",
            cardClass: "status-sticker-type-3",
          };
        } else {
          return {
            className: "status-rejected",
            value: "Rejected",
            gridClass: "status-type-5",
            cardClass: "status-sticker-type-5",
          };
        }
      case "closed":
        return {
          className: "status-rejected",
          value: "Closed",
          gridClass: "status-type-5",
          cardClass: "status-sticker-type-5",
        };
      case "no_show":
        if (tab === 1) {
          return {
            className: "status-shortlisted",
            value: "Completed",
            gridClass: "status-type-3",
            cardClass: "status-sticker-type-3",
          };
        } else {
          return {
            className: "status-shortlisted",
            value: "No Show",
            gridClass: "status-type-4",
            cardClass: "status-sticker-type-4",
          };
        }
      case "conducted":
        return {
          className: "status-shortlisted",
          value: "Conducted",
          gridClass: "status-type-3",
          cardClass: "status-sticker-type-3",
        };
      case "noshow":
        if (tab === 1) {
          return {
            className: "status-shortlisted",
            value: "Completed",
            gridClass: "status-type-3",
            cardClass: "status-sticker-type-3",
          };
        } else {
          return {
            className: "status-shortlisted",
            value: "No Show",
            gridClass: "status-type-4",
            cardClass: "status-sticker-type-4",
          };
        }
      case "cancelled":
        return {
          className: "status-cancelled",
          value: "Canceled",
          gridClass: "status-type-5",
          cardClass: "status-sticker-type-5",
        };
      case "deleted":
        return {
          className: "status-cancelled",
          value: "Deleted",
          gridClass: "status-type-5",
          cardClass: "status-sticker-type-5",
        };
      case "very_poor":
        if (tab === 1) {
          return {
            className: "status-shortlisted",
            value: "Completed",
            gridClass: "status-type-3",
            cardClass: "status-sticker-type-3",
          };
        } else {
          return {
            className: "status-rejected",
            value: "Very Poor",
            gridClass: "status-type-5",
            cardClass: "status-sticker-type-5",
          };
        }
      case "poor":
        if (tab === 1) {
          return {
            className: "status-shortlisted",
            value: "Completed",
            gridClass: "status-type-3",
            cardClass: "status-sticker-type-3",
          };
        } else {
          return {
            className: "status-rejected",
            value: "Poor",
            gridClass: "status-type-5",
            cardClass: "status-sticker-type-5",
          };
        }
      case "average":
        if (tab === 1) {
          return {
            className: "status-shortlisted",
            value: "Completed",
            gridClass: "status-type-3",
            cardClass: "status-sticker-type-3",
          };
        } else {
          return {
            className: "status-scheduled",
            value: "Average",
            gridClass: "status-type-6",
            cardClass: "status-sticker-type-6",
          };
        }
      case "above_average":
        if (tab === 1) {
          return {
            className: "status-shortlisted",
            value: "Completed",
            gridClass: "status-type-3",
            cardClass: "status-sticker-type-3",
          };
        } else {
          return {
            className: "status-scheduled",
            value: "Above Average",
            gridClass: "status-type-6",
            cardClass: "status-sticker-type-6",
          };
        }
      case "good":
        if (tab === 1) {
          return {
            className: "status-shortlisted",
            value: "Completed",
            gridClass: "status-type-3",
            cardClass: "status-sticker-type-3",
          };
        } else {
          return {
            className: "status-shortlisted",
            value: "Good",
            gridClass: "status-type-3",
            cardClass: "status-sticker-type-3",
          };
        }
      case "very_good":
        if (tab === 1) {
          return {
            className: "status-shortlisted",
            value: "Completed",
            gridClass: "status-type-3",
            cardClass: "status-sticker-type-3",
          };
        } else {
          return {
            className: "status-shortlisted",
            value: "Very Good",
            gridClass: "status-type-3",
            cardClass: "status-sticker-type-3",
          };
        }
      case "excellent":
        if (tab === 1) {
          return {
            className: "status-shortlisted",
            value: "Completed",
            gridClass: "status-type-3",
            cardClass: "status-sticker-type-3",
          };
        } else {
          return {
            className: "status-shortlisted",
            value: "Excellent",
            gridClass: "status-type-3",
            cardClass: "status-sticker-type-3",
          };
        }
      case "Not Recommended":
        return {
          className: "status-rejected",
          value: "Not Recommended",
          gridClass: "status-type-5",
          cardClass: "status-sticker-type-5",
        };
      default:
        return {
          className: "status-pending",
          value: "Pending",
          gridClass: "status-type-1",
          cardClass: "status-sticker-type-1",
        };
    }
  } else {
    return "";
  }
};

export const incbotStatusClass = (status) => {
  if (status) {
    switch (status.toLowerCase()) {
      case "pending":
        return {
          className: "status-pending",
          value: "Invited",
          gridClass: "status-type-1",
          cardClass: "status-pending-card",
        };
      case "active":
        return {
          className: "status-completed",
          value: "Active",
          gridClass: "status-type-3",
          cardClass: "status-sticker-type-1",
        };
      case "recommended":
        return {
          className: "status-completed",
          value: "Recommended",
          gridClass: "status-type-3",
          cardClass: "status-sticker-type-3",
        };
      case "not recommended":
        return {
          className: "status-rejected",
          value: "Not Recommended",
          gridClass: "status-type-5",
          cardClass: "status-not-recommended-card",
        };
      case "closed":
        return {
          className: "status-rejected",
          value: "Closed",
          gridClass: "status-type-5",
          cardClass: "status-sticker-type-5",
        };
      case "deleted":
        return {
          className: "status-pending",
          value: "Deleted",
          gridClass: "status-pending",
          cardClass: "status-pending-card",
        };
      case "unattended":
        return {
          className: "status-rejected",
          value: "Unattended",
          gridClass: "status-type-5",
          cardClass: "status-pending-card",
        };
      case "cautiously recommended":
        return {
          className: "status-cautiouslyrecommended",
          value: "Cautiously Recommended",
          gridClass: "status-cautiouslyrecommended",
          cardClass: "status-cautiouslyrecommended-card",
        };
      default:
        return "";
    }
  } else {
    return "";
  }
};

export const convertIntoQueryString = (data) => {
  return qs.stringify(data);
};

export const checkLoginTime = () => {
  let refreshToken = localStorageJsonParseDecrypt("refreshToken");
  let accessTokenExpiresAt = localStorageJsonParseDecrypt("expiryTime");
  var accessTokenExpiresAtInMs = new Date(accessTokenExpiresAt).getTime();
  let isValid = true;
  let currentTime = new Date().getTime();
  let diffTime = currentTime - accessTokenExpiresAtInMs;
  if (currentTime < accessTokenExpiresAtInMs) {
    isValid = true;
  } else if (diffTime >= 1800000) {
    localStorage.clear();
    window.location.href = "/login";
  } else if (diffTime < 1800000) {
    axios
      .post(`${generateUrl("user_backend")}/admin/auth/oauth/validate-token`, {
        token: refreshToken,
      })
      .then(function (response) {
        if (response.data.result?.status === 0) {
          localStorage.clear();
          window.location.href = "/login";
        } else {
          isValid = true;
          localStorageEncrypt(
            "userData",
            JSON.stringify(response.data.result?.user)
          );
          localStorageEncrypt(
            "token",
            JSON.stringify(response.data.result?.accessToken)
          );
          localStorageEncrypt(
            "refreshToken",
            JSON.stringify(response.data.result?.refreshToken)
          );
          localStorageEncrypt(
            "expiryTime",
            JSON.stringify(response.data.result?.expiryTime)
          );
        }
      })
      .catch(function (error) {
        localStorage.clear();
        window.location.href = "/login";
      });
  }
  return isValid;
};

export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
};

export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const capitalizeAll = (s) => {
  if (typeof s !== "string") return "";
  return s
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const downloadFile = (fileUrl, fileName) => {
  let a = document.createElement("a");
  a.href = fileUrl;
  a.download = fileName;
  a.target = "_blank";
  a.click();
};

export const getYearsFrom1900ToCurrentYear = () => {
  const date = new Date();
  const currentYear = date.getFullYear();
  const totalYear = currentYear - 1900;
  const year = [];
  for (let i = totalYear; i >= 0; i--) {
    year.push(currentYear - i);
  }
  return year;
};

export const useOutsideAlerter = (ref, setIsOpen) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, setIsOpen]);
};
export const Toast = ({ msg }) => (
  <div style={{ wordBreak: "normal" }}>{msg}</div>
);

// export const Toast = ({ msg }) => <>{msg}</>;

export const getOptions = (data) => {
  const newArray = [];
  data.forEach((element) =>
    newArray.push({
      value: element,
      label: element,
    })
  );
  return newArray;
};

export const checkFileValidation = (files, type) => {
  let isValid = false;
  if (type === "image") {
    if (
      files.type === "image/jpeg" ||
      files.type === "image/png" ||
      files.type === "image/jpg"
    ) {
      isValid = true;
    }
  } else if (type === "zip") {
    if (
      files.type === "zip" ||
      files.type === "application/octet-stream" ||
      files.type === "application/zip" ||
      files.type === "application/x-zip" ||
      files.type === "application/x-zip-compressed"
    ) {
      isValid = true;
    }
  } else if (type === "xlsx") {
    if (
      // files.type === "application/zip" ||
      files.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      isValid = true;
    }
  } else if (type === "csv") {
    if (files.type === "text/csv") {
      isValid = true;
    }
  } else if (type === "CAR") {
    if (
      files.type === "text/plain" ||
      files.type === "application/vnd.ms-excel" ||
      files.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      files.type === "text/csv" ||
      files.type === "application/pdf" ||
      files.type === "application/msword" ||
      files.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      isValid = true;
    }
  } else {
    if (
      files.type === "application/pdf" ||
      files.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      files.type === "application/msword"
    ) {
      isValid = true;
    }
  }
  return isValid;
};

export const exportToCSV = (apiData, fileName) => {
  const objectMaxLength = [];
  const wscols = [];
  for (let i = 0; i < apiData?.length; i++) {
    let value = Object.values(apiData[i]);
    for (let j = 0; j < value?.length; j++) {
      if (typeof value[j] === "number") {
        objectMaxLength[j] = 10;
      } else {
        if (value[j] !== undefined) {
          objectMaxLength[j] =
            objectMaxLength[j] >= value[j]?.length
              ? objectMaxLength[j]
              : value[j]?.length;
        }
      }
    }
  }
  if (apiData && apiData[0]) {
    for (let i = 0; i < Object?.keys(apiData[0])?.length; i++) {
      wscols.push({ width: objectMaxLength[i] });
    }
  }
  const ws = XLSX.utils.json_to_sheet(apiData);
  ws["!cols"] = wscols;
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};

export const localStorageEncrypt = (storageKey, data) => {
  let Key = storageKey;
  localStorageName
    .filter((itm) => itm.key === storageKey)
    .map((itm) => {
      Key = itm.value;
    });
  var value = CryptoJS.AES.encrypt(data, encryptKey).toString();
  localStorage.setItem(Key, value);
};

export const localStorageDecrypt = (storageKey) => {
  let Key = storageKey;
  localStorageName
    .filter((itm) => itm.key === storageKey)
    .map((itm) => {
      Key = itm.value;
    });
  var data;
  if (localStorage.getItem(Key)) {
    var bytes = CryptoJS.AES.decrypt(localStorage.getItem(Key), encryptKey);
    data = bytes.toString(CryptoJS.enc.Utf8);
  } else {
    data = "";
  }
  return data;
};

export const localStorageJsonParseDecrypt = (storageKey) => {
  let Key = storageKey;
  localStorageName
    .filter((itm) => itm.key === storageKey)
    .map((itm) => {
      Key = itm.value;
    });
  var data;
  if (localStorage.getItem(Key)) {
    var bytes = CryptoJS.AES.decrypt(localStorage.getItem(Key), encryptKey);
    data = bytes.toString(CryptoJS.enc.Utf8);
    if (data) {
      data = JSON.parse(data);
    } else {
      data = "";
    }
  } else {
    data = "";
  }
  return data;
};

export const localStorageRemove = (storageKey) => {
  let Key = storageKey;
  localStorageName
    .filter((itm) => itm.key === storageKey)
    .map((itm) => {
      Key = itm.value;
    });
  localStorage.removeItem(Key);
};

export const Rating = {
  1: "Very Poor",
  2: "Very Poor",
  3: "Very Poor",
  4: "Poor",
  5: "Average",
  6: "Good",
  7: "Very Good",
  8: "Very Good",
  9: "Excellent",
  10: "Excellent",
};

export const checkMenu = (resource) => {
  if (resource) {
    // let activeList = permissions.filter((list) => list?.name === resource);
    // if (activeList.length > 0) {
    //   return true;
    // } else {
    //   return false;
    // }
    return true;
  } else {
    return false;
  }
};

//functins for user permissions//
// export const checkPermission = (resource) => {
//   let permissions = localStorageJsonParseDecrypt("permissions");

//   let userType = localStorageJsonParseDecrypt("userData").userType;
//   if (userType === "admin") {
//     return true;
//   } else {
//     if (permissions && resource) {
//       if (permissions.some((list) => list?.status && list?.name === resource)) {
//         return true;
//       } else {
//         return false;
//       }
//     } else {
//       return false;
//     }
//   }
// };

export const JobTitleChecker = (value) => {
  return value.replace(/[^A-Za-z\s\,\(\)\.\-\+\#]/g, "");
};
export const checkPermissions = (mainResource, resources, action) => {
  const permissionsList = localStorageJsonParseDecrypt("permissions");
  const userType = localStorageJsonParseDecrypt("userData").userType;
  if (userType === "admin") {
    return true;
  }

  if (!permissionsList || !resources) {
    return false;
  }

  const activeList =
    permissionsList &&
    permissionsList?.length > 0 &&
    permissionsList?.find((itm) => itm?.name && itm?.name === mainResource);

  if (activeList?.status) {
    if (["IncServe", "IncExit"].includes(resources)) {
      return activeList.status;
    } else {
      const resourcesList = activeList.resources.find(
        (list) => list?.name === resources
      );

      if (resourcesList?.status) {
        const actionList = resourcesList.actions.find(
          (list) => list?.name === action
        );

        if (actionList?.status) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  } else {
    return false;
  }
};

export const hasAccess = (content, permissionsList, action) => {
  const mainResource = content?.mainResource ? content?.mainResource : "";
  const resource = content?.resource ? content?.resource : "";
  const subResource = content?.subResource ? content?.subResource : "";
  const userType = localStorageJsonParseDecrypt("userData")?.userType;

  if (userType === "admin") {
    return true;
  }

  if (!mainResource || !resource) {
    return true;
  }

  const mainResourceDetails = permissionsList?.find(
    (itm) => itm?.key === mainResource
  );

  if (mainResourceDetails?.status) {
    const resourceDetails = mainResourceDetails?.resources?.find(
      (list) => list?.key === resource
    );

    if (Array.isArray(resourceDetails?.actions)) {
      if (
        !subResource &&
        (resourceDetails?.type !== 1 || resourceDetails?.status)
      ) {
        return true;
      }
      if (
        subResource &&
        (resourceDetails?.type !== 1 || resourceDetails?.status)
      ) {
        const subResourceDetails = resourceDetails?.actions?.find(
          (data) => data?.key === subResource
        );
        return subResourceDetails?.actions?.[
          subResourceDetails?.type === 0 ? "status" : action
        ];
      }
    } else {
      return resourceDetails?.actions?.[
        resourceDetails?.type === 0 ? "status" : action
      ];
    }
  } else {
    return false;
  }
};

export const adminMenu = [
  {
    title: "Dashboard",
    img: dashboardLogo,
    path: "/",
    mainResource: "",
    resource: "",
    subMenu: [],
  },
  {
    title: "Manage Products",
    img: productManage,
    path: "",
    mainResource: "commonPermission",
    resource: "manageProductsPlans",
    active: false,
    subMenu: [
      {
        title: "Add/Edit",
        path: "/products",
        subMenu: [],
        mainResource: "commonPermission",
        resource: "manageProductsPlans",
      },
      {
        title: "Custom Plans",
        path: "/custom-plans",
        mainResource: "commonPermission",
        resource: "manageProductsPlans",
        subMenu: [],
      },
    ],
  },
  {
    title: "Client Management",
    img: employerManage,
    path: "",
    mainResource: "commonPermission",
    resource: "clientManagement",
    active: false,
    subMenu: [
      {
        title: "Pending Profile",
        path: "/employer/email",
        mainResource: "commonPermission",
        resource: "clientManagement",
        subResource: "pendingProfile",
        subMenu: [],
      },
      {
        title: "Approved Clients",
        path: "/employer/profile-management",
        mainResource: "commonPermission",
        resource: "clientManagement",
        subResource: "approvedProfile",
        subMenu: [],
      },
      {
        title: "Subscribed Clients",
        path: "/employer/subscriber-list",
        mainResource: "commonPermission",
        resource: "clientManagement",
        subResource: "subscribedClients",
        subMenu: [],
      },
      {
        title: "Suspended Clients",
        path: "/employer/suspended-page",
        mainResource: "commonPermission",
        resource: "clientManagement",
        subResource: "suspendedClients",
        subMenu: [],
      },
      {
        title: "Deleted Clients",
        path: "/employer/deleted-page",
        mainResource: "commonPermission",
        resource: "clientManagement",
        subResource: "deletedClients",
        subMenu: [],
      },
    ],
  },
  {
    title: "Sub Users",
    img: roleSubuser,
    path: "",
    mainResource: "commonPermission",
    resource: "otherConfigurations",
    subResource: "rolesSubusers",
    active: false,
    subMenu: [
      {
        title: "Roles",
        path: "/admin-roles",
        mainResource: "commonPermission",
        resource: "otherConfigurations",
        subResource: "rolesSubusers",
        subMenu: [],
      },
      {
        title: "Sub Users",
        path: "/admin-subusers",
        mainResource: "commonPermission",
        resource: "otherConfigurations",
        subResource: "rolesSubusers",
        subMenu: [],
      },
      {
        title: "Deleted Users",
        path: "/admin-deleteusers",
        mainResource: "",
        resource: "",
        subResource: "",
        subMenu: [],
      },
    ],
  },
  {
    title: "InCServe",
    img: incserveAdminLogo,
    path: "",
    active: false,
    subMenu: [
      {
        title: "Search",
        path: "/incserve/search",
        subMenu: [],
      },

      {
        title: "Manage Position",
        img: roleSubuser,
        path: "/incserve/manage-positions",
        subMenu: [],
      },
      {
        title: "Manage Interviews",
        path: "",
        img: interview,
        active: false,
        subMenu: [
          {
            title: "New Requirements",
            path: "/incserve/manage-interviews/requirements",
            subMenu: [],
          },
          {
            title: "Scheduled",
            path: "/incserve/manage-interviews/scheduled",
            subMenu: [],
          },
          {
            title: "Completed",
            path: "/incserve/manage-interviews/completed",
            subMenu: [],
          },
          {
            title: "Deleted",
            path: "/incserve/manage-interviews/deleted",
            subMenu: [],
          },
        ],
      },
      {
        title: "Interviewer List",
        path: "",
        img: panelist,
        active: false,
        subMenu: [
          {
            title: "Pending Profile",
            path: "/interviewer/pending-profile",
            subMenu: [],
          },
          {
            title: "Approval Pending",
            path: "/interviewer/approval-pending",
            subMenu: [],
          },
          {
            title: "Approved Profile",
            path: "/interviewer/approved-profile",
            subMenu: [],
          },
          {
            title: "Active Profile",
            path: "/interviewer/active-profile",
            subMenu: [],
          },
          {
            title: "Deleted Profile",
            path: "/interviewer/deleted-profile",
            subMenu: [],
          },
          {
            title: "Blacklisted Profile",
            path: "/interviewer/blacklisted-profile",
            subMenu: [],
          },
          {
            title: "Hold Profile",
            path: "/interviewer/hold-interviewer",
            subMenu: [],
          },

          {
            title: "Deactivated Profile",
            path: "/interviewer/deactivated-profile",
            subMenu: [],
          },
        ],
      },
    ],
  },
  {
    title: "InCExit",
    img: incexitAdminLogo,
    path: "",
    active: false,
    subMenu: [
      {
        title: "Search",
        img: search_icon,
        path: "/incexit/search",
        subMenu: [],
      },
      {
        title: "Manage Interviews",
        img: interview,
        resourceName: "Manage Interviews",
        path: "",
        active: false,
        subMenu: [
          {
            title: "New Requirements",
            path: "/incexit/manage-interviews/requirements",
            subMenu: [],
          },
          {
            title: "Scheduled",
            path: "/incexit/manage-interviews/scheduled",
            subMenu: [],
          },
          {
            title: "Completed",
            path: "/incexit/manage-interviews/completed",
            subMenu: [],
          },
          {
            title: "Deleted",
            path: "/incexit/manage-interviews/deleted",
            subMenu: [],
          },
        ],
      },
    ],
  },
  {
    title: "InCBot",
    img: incexitAdminLogo,
    path: "",
    active: false,
    subMenu: [
      {
        title: "Search",
        img: search_icon,
        path: "/incbot/search",
        subMenu: [],
      },
      {
        title: "Manage Position",
        img: interview,
        path: "/incbot/position",
        mainResource: "incBot",
        resource: "positions",
        active: false,
        subMenu: [],
      },
      {
        title: "Manage Interviews",
        img: interview,
        path: "/incbot/interviews/",
        mainResource: "incBot",
        resource: "interviewManagement",
        active: false,
        subMenu: [],
      },
    ],
  },
  {
    title: "Contact Inquiries",
    img: contactInquiries,
    path: "/contact",
    subMenu: [],
  },
  {
    title: "Manage Skill Sets",
    img: positionsManage,
    permission: checkPermissions("Manage Skills Sets"),
    path: "/positions-list",
    subMenu: [],
  },
  {
    title: "Announcements",
    img: announcements,
    path: "/announcement-list",
    mainResource: "commonPermission",
    resource: "otherConfigurations",
    subResource: "announcements",
    subMenu: [],
  },
  {
    path: "/careers",
    subMenu: [],
    title: "Careers",
    img: career,
    mainResource: "commonPermission",
    resource: "otherConfigurations",
    subResource: "careers",
  },
  {
    title: "Support",
    img: support,
    path: "/support",
    subMenu: [],
    mainResource: "commonPermission",
    resource: "otherConfigurations",
    subResource: "support",
  },
  {
    title: "Question Bank",
    img: questionBank,
    path: "/question-banks",
    subMenu: [],
  },
  {
    title: "Blogs, News & FAQ",
    img: roleSubuser,
    path: "",
    mainResource: "commonPermission",
    resource: "otherConfigurations",
    subResource: "rolesSubusers",
    active: false,
    subMenu: [
      {
        title: "FAQ",
        path: "/faq",
        subMenu: [],
        mainResource: "commonPermission",
        resource: "otherConfigurations",
        subResource: "faq",
      },
      {
        title: "News Article",
        path: "/news-article",
        subMenu: [],
        mainResource: "commonPermission",
        resource: "otherConfigurations",
        subResource: "websiteNews",
      },
      {
        title: "Blogs",
        path: "/blogs",
        mainResource: "commonPermission",
        resource: "otherConfigurations",
        subResource: "rolesSubusers",
        subMenu: [],
      },
    ],
  },
  {
    title: "Meetings",
    img: meeting,
    path: "/meetings",
    subMenu: [],
    mainResource: "commonPermission",
    resource: "otherConfigurations",
    subResource: "meetings",
  },
  {
    title: "Event Tracking",
    img: eventTracking,
    path: "/event-tracking",
    subMenu: [],
    mainResource: "commonPermission",
    resource: "otherConfigurations",
    subResource: "meetings",
  },
  {
    title: "2 Factor Security",
    img: twoFactor,
    path: "/two-factor-authentication",
    subMenu: [],
    mainResource: "commonPermission",
    resource: "otherConfigurations",
    subResource: "twoFactorSecurity",
  },
  {
    title: "API Call Logs",
    img: apiCallLogs,
    path: "/api-call-Logs",
    subMenu: [],
    mainResource: "commonPermission",
    resource: "otherConfigurations",
    subResource: "apiCallLogs",
  },
  {
    title: "Mock Interviews",
    img: mockInterviewIcon,
    path: "",
    mainResource: "commonPermission",
    resource: "otherConfigurations",
    subResource: "mockInterviews",
    active: false,
    subMenu: [
      {
        title: "Candidates",
        path: "/mock-interview-candidates",
        subMenu: [],
        mainResource: "commonPermission",
        resource: "otherConfigurations",
        subResource: "mockInterviews",
      },
      {
        title: "Interviews",
        path: "/mock-interview-interviews",
        subMenu: [],
        mainResource: "commonPermission",
        resource: "otherConfigurations",
        subResource: "mockInterviews",
      },
      {
        title: "Coupon Code",
        path: "/mock-interview-coupon-code",
        subMenu: [],
        mainResource: "commonPermission",
        resource: "otherConfigurations",
        subResource: "mockInterviews",
      },
      {
        title: "Mock Interviews",
        path: "/mock-interviews",
        subMenu: [],
        mainResource: "commonPermission",
        resource: "otherConfigurations",
        subResource: "mockInterviews",
      },
    ],
  },
];

export const CheckProduct = (productId) => {
  if (productId === "635fb2cab8f7d26ec8edb696") {
    return "incserve";
  } else if (productId === "645381833fc466664a6a209f") {
    return "incexit";
  } else if (productId === "") {
    return "incbot";
  } else {
    return "";
  }
};

export const CheckPath = (itemType, product, status, type) => {
  const itemTypeLower = itemType?.toLowerCase().trim();
  const productLower = product?.toLowerCase().trim();

  let path;
  if (itemTypeLower === "employersignup") {
    path = "employer/email";
  } else if (itemTypeLower === "employersubuserpost") {
    path = "admin-subusers";
  } else if (itemTypeLower === "interviewersignup") {
    path = "interviewer/pending-profile";
  } else if (itemTypeLower === "employerprofileupdate") {
    path = "employer-company-profile";
  } else if (
    itemTypeLower === "employerdeleteinterview" &&
    productLower === "incserve"
  ) {
    path = "/manage-interviews/deleted";
  } else if (
    itemTypeLower === "employerpositioncreate" &&
    productLower === "incserve"
  ) {
    path = "/manage-positions";
  } else if (
    itemTypeLower === "employerpositionupdate" &&
    productLower === "incserve"
  ) {
    path = "/manage-positions";
  } else if (
    itemTypeLower === "employerbulkpost" &&
    productLower === "incserve"
  ) {
    path = "/manage-interviews/requirements";
  } else if (
    itemTypeLower === "employerupdateinterview" &&
    productLower === "incserve"
  ) {
    path = "/manage-interviews/requirements";
  } else if (itemTypeLower === "interviews" && productLower === "incserve") {
    if (
      status === "pending" ||
      status === "alloted" ||
      status === "accepted" ||
      type === "reallot_interviewer" ||
      type === "allot_interviewer" ||
      type === "employer_bulk_post"
    ) {
      path = "/manage-interviews/requirements";
    } else if (
      status === "scheduled" ||
      type === "requirement_schedule" ||
      type === "reschedule_requested" ||
      type === "interviewer_accepted" ||
      type === "reschedule_accepted"
    ) {
      path = "/manage-interviews/scheduled";
    } else if (
      status === "completed" ||
      type === "feedback_approved" ||
      type === "cancel_requirement"
    ) {
      path = "/manage-interviews/completed";
    } else if (status === "deleted") {
      path = "/manage-interviews/deleted";
    }
  } else if (itemTypeLower === "interviews" && productLower === "incexit") {
    if (status === "pending" || status === "alloted" || status === "accepted") {
      path = "/manage-interviews/requirements";
    } else if (status === "scheduled") {
      path = "/manage-interviews/scheduled";
    } else if (status === "completed") {
      path = "/manage-interviews/completed";
    } else if (status === "deleted") {
      path = "/manage-interviews/deleted";
    }
  }
  return path;
};

export const notificationRedirect = (data) => {
  let token = getToken();
  const domain = generateUrl("admin");
  if (data && data?.data?.itemType) {
    const itemType = data?.data?.itemType;
    const productId = CheckProduct(data?.productId);
    const status = data?.data?.status;
    const type = data?.type;
    const path = CheckPath(itemType, productId, status, type);
    if (path) {
      window.location.href = `${domain}${productId}${path}?token=${token}&itemId=${
        data?.data?.itemId
      }&search=${encodeURIComponent(
        data?.data?.search
      )}&status=${status}&searchType=${"code"}&type=${data?.type}&name=${
        data?.data?.name
      }`;
    }
  }
};

export const getResource = (resource, permissions) => {
  let resourceData =
    permissions && permissions.filter((itm) => itm.name === resource);
  let index = permissions && permissions.findIndex((x) => x.name === resource);
  return resourceData
    ? { ...resourceData[0], position: index ? index : 0 }
    : { resourceStatus: "inactive", actions: [], position: index ? index : 0 };
};

export const timeSlots = [
  "00:00",
  "00:15",
  "00:30",
  "00:45",
  "01:00",
  "01:15",
  "01:30",
  "01:45",
  "02:00",
  "02:15",
  "02:30",
  "02:45",
  "03:00",
  "03:15",
  "03:30",
  "03:45",
  "04:00",
  "04:15",
  "04:30",
  "04:45",
  "05:00",
  "05:15",
  "05:30",
  "05:45",
  "06:00",
  "06:15",
  "06:30",
  "06:45",
  "07:00",
  "07:15",
  "07:30",
  "07:45",
  "08:00",
  "08:15",
  "08:30",
  "08:45",
  "09:00",
  "09:15",
  "09:30",
  "09:45",
  "10:00",
  "10:15",
  "10:30",
  "10:45",
  "11:00",
  "11:15",
  "11:30",
  "11:45",
  "12:00",
  "12:15",
  "12:30",
  "12:45",
  "13:00",
  "13:15",
  "13:30",
  "13:45",
  "14:00",
  "14:15",
  "14:30",
  "14:45",
  "15:00",
  "15:15",
  "15:30",
  "15:45",
  "16:00",
  "16:15",
  "16:30",
  "16:45",
  "17:00",
  "17:15",
  "17:30",
  "17:45",
  "18:00",
  "18:15",
  "18:30",
  "18:45",
  "19:00",
  "19:15",
  "19:30",
  "19:45",
  "20:00",
  "20:15",
  "20:30",
  "20:45",
  "21:00",
  "21:15",
  "21:30",
  "21:45",
  "22:00",
  "22:15",
  "22:30",
  "22:45",
  "23:00",
  "23:15",
  "23:30",
  "23:45",
];

export const ArrayForExpRange = () => {
  const tmpArray = [
    {
      label: "Select exp",
      value: "",
    },
  ];
  Array.from({ length: 21 }, (_, index) => index).map((itm) => {
    tmpArray.push({
      value: itm,
      label: itm,
    });
  });
  return tmpArray;
};

export const roleNameChecker = (value) => {
  return value.replace(/[^a-zA-Z0-9\s\.,()\-_+:]/g, "");
};

export const nameChecker = (value) => {
  return value.replace(/[^A-Za-z\s\.]/g, "");
};
export const numberChecker = (value) => {
  return value.replace(/\D/g, "");
};
export const mailChecker = (value) => {
  return value.replace(/[^a-zA-Z0-9\_\-\@\.\+]/g, "");
};
export const designationChecker = (value) => {
  return value.replace(/[^a-zA-Z0-9]/g, "");
};
export const contactNumberChecker = (value) => {
  return value.replace(/[^0-9]/g, "").slice(0, 10);
};
export const remarksChecker = (value) => {
  return value.replace(
    /[^A-Za-z0-9\s\!\@\#\$\%\&\*\(\)\_\-\+\=\[\]\{\}\/\?\.\,\;\:\'\"\/]/g,
    ""
  );
};
export const addressChecker = (value) => {
  return value.replace(/[^A-Za-z0-9\s\@\#\%\&\*\-\.\,\:\'\"\/]/g, "");
};
export const skillChecker = (value) => {
  return value.replace(/[^A-Za-z0-9\s\,\(\)\.\-\+\#]/g, "");
};
export const departmentChecker = (value) => {
  return value.replace(/[^A-Za-z0-9\s\,\(\)\.]/g, "");
};
export const companyNameChecker = (value) => {
  return value.replace(/[^A-Za-z0-9\s\,\-\;\:\.\#\$\@\&]/g, "");
};
export const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(remainingSeconds).padStart(2, "0");
  return `${formattedMinutes}:${formattedSeconds} mins`;
};
export const finalStatusCheck = [
  "Cautiously Recommended",
  "Not Recommended",
  "Recommended",
];
export const yearsExperience = () => {
  const tmpArray = [
    {
      label: "Select Exp.",
      value: "",
    },
  ];
  Array.from({ length: 91 }, (_, index) => index).map((itm) => {
    tmpArray.push({
      value: itm + 1,
      label: itm + 1,
    });
  });
  return tmpArray;
};

export const monthsExperience = () => {
  const tmpArray = [];
  Array.from({ length: 12 }, (_, index) => index).map((itm) => {
    tmpArray.push({
      value: itm + 1,
      label: itm + 1,
    });
  });
  return tmpArray;
};

export const mailContent = (userData) => {
  let content = {
    candidateHeader: "",
    candidateBody: "",
    interviewerHeader: "",
    interviewerBody: "",
  };
  content.candidateHeader = `Hi ${capitalizeAll(
    userData?.candidate?.name
  )},\n\nYour interview for ${capitalizeAll(
    userData?.position?.title
  )} at ${capitalizeAll(
    userData?.employer[0]?.company?.name
  )} has been scheduled on`;

  content.candidateBody = `For a smooth interview, kindly ensure of the below -\n\n1. Use Chrome Browser in Windows or Safari in Mac for best interview experience.\n2. Use your laptop and not a smartphone for the Interview.\n3. Check for good internet connectivity to avoid glitches and lag during the Interview.\n4. Please ensure you are free from a noisy background or surroundings.\n5. Login 5 mins early to ensure all of the above are in place.\nFeel free to connect in case of any further clarifications required.\n\nRegards,\n\n${capitalizeAll(
    userData?.createdBy?.name
  )}\n${capitalizeAll(userData?.employer[0]?.company?.name)}`;

  content.interviewerHeader = `Hi ${capitalizeAll(
    userData?.interviewer?.name
  )},\n\n${capitalizeAll(
    userData?.employer[0]?.company?.name
  )} has scheduled an interview with a Candidate ${capitalizeAll(
    userData?.candidate?.name
  )} for ${capitalizeAll(userData?.position?.title)}.`;

  content.interviewerBody = `For a smooth interview, kindly ensure of the below -\n\n1. Use Chrome Browser in Windows or Safari in Mac for best interview experience.\n2. Use your laptop and not a smartphone for the Interview.\n3. Check for good internet connectivity to avoid glitches and lag during the Interview.\n4. Please ensure you are free from a noisy background or surroundings.\n5. Login 5 mins early to ensure all of the above are in place.\nFeel free to connect in case of any further clarifications required.\n\nRegards,\n\n${capitalizeAll(
    userData?.createdBy?.name
  )}\n${capitalizeAll(userData?.employer[0]?.company?.name)}`;

  return content;
};

export const completedStatus = [
  "shortlisted",
  "hold",
  "rejected",
  "very_poor",
  "poor",
  "average",
  "above_average",
  "good",
  "very_good",
  "excellent",
  "Recommended",
  "Not Recommended",
  "Cautiously Recommended",
];
export const scheduledStatus = ["scheduled", "rescheduled"];
export const convertToTitleCase = (str) => {
  return str
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};
const date = new Date();
const year = date.toISOString().split("-")[0];
const month = date.toISOString().split("-")[1];

export const numberToWords = (number) => {
  const ones = [
    "",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
  ];
  const tens = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];
  const scales = ["", "thousand", "million", "billion", "trillion"];

  if (number === 0) return "zero";

  // Convert number to string and pad with leading zeros if necessary
  const numStr = String(number).padStart(12, "0");

  // Split the number string into chunks of 3 digits each
  const chunks = numStr.match(/.{1,3}/g).map(Number);

  // Convert each chunk to words and concatenate them with the appropriate scale
  let result = chunks
    .map((chunk, index) => {
      if (chunk === 0) return "";
      const hundreds = Math.floor(chunk / 100);
      const tensAndOnes = chunk % 100;

      let chunkWords = "";

      if (hundreds > 0) {
        chunkWords += ones[hundreds] + " hundred ";
      }

      if (tensAndOnes > 0) {
        if (tensAndOnes < 20) {
          chunkWords += ones[tensAndOnes];
        } else {
          const tensDigit = Math.floor(tensAndOnes / 10);
          const onesDigit = tensAndOnes % 10;
          chunkWords += tens[tensDigit];
          if (onesDigit > 0) {
            chunkWords += "-" + ones[onesDigit];
          }
        }
      }

      if (chunkWords.endsWith("-")) {
        chunkWords = chunkWords.slice(0, -1);
      }

      return chunkWords + " " + scales[chunks.length - index - 1];
    })
    .filter(Boolean)
    .join(" ");

  // Remove any extra spaces and return the result
  return result.trim();
};

export const monthsOption = [
  {
    value: "Jan",
    label: "Jan",
  },
  {
    value: "Feb",
    label: "Feb",
  },
  {
    value: "Mar",
    label: "Mar",
  },
  {
    value: "Apr",
    label: "Apr",
  },
  {
    value: "May",
    label: "May",
  },
  {
    value: "Jun",
    label: "Jun",
  },
  {
    value: "Jul",
    label: "Jul",
  },
  {
    value: "Aug",
    label: "Aug",
  },
  {
    value: "Sep",
    label: "Sep",
  },
  {
    value: "Oct",
    label: "Oct",
  },
  {
    value: "Nov",
    label: "Nov",
  },
  {
    value: "Dec",
    label: "Dec",
  },
];

export const filterTodateName = (pageName, tab) => {
  let toText = "";

  if (pageName === "AllRequirement") {
    if (tab === 1 || tab === 4) {
      toText = "updatedTo";
    } else if (tab === 2) {
      toText = "acceptedTo";
    } else if (tab === 3) {
      toText = "allotedTo";
    }
  } else if (pageName === "ScheduledRequirement") {
    if (tab === 1 || tab === 4) {
      toText = "rescheduledTo";
    } else if (tab === 2 || tab === 3 || tab === 5) {
      toText = "scheduledTo";
    }
  } else if (pageName === "CompletedRequirement") {
    if (
      tab === 1 ||
      tab === 2 ||
      tab === 5 ||
      tab === 6 ||
      tab === 7 ||
      tab === 8 ||
      tab === 9 ||
      tab === 10
    ) {
      toText = "submittedTo";
    } else if (tab === 3) {
      toText = "scheduledTo";
    } else if (tab === 4) {
      toText = "canceledTo";
    }
  } else if (pageName === "DeletedRequirement") {
    if (tab === 1 || tab === 2) {
      toText = "createdTo";
    }
  } else {
    toText = "to";
  }
  return toText;
};

export const filterFromdateName = (pageName, tab) => {
  let formText = "";
  if (pageName === "AllRequirement") {
    if (tab === 1 || tab === 4) {
      formText = "updatedFrom";
    } else if (tab === 2) {
      formText = "acceptedFrom";
    } else if (tab === 3) {
      formText = "allotedForm";
    }
  } else if (pageName === "ScheduledRequirement") {
    if (tab === 1 || tab === 4) {
      formText = "rescheduledFrom";
    } else if (tab === 2 || tab === 3 || tab === 5) {
      formText = "scheduledFrom";
    }
  } else if (pageName === "CompletedRequirement") {
    if (
      tab === 1 ||
      tab === 2 ||
      tab === 5 ||
      tab === 6 ||
      tab === 7 ||
      tab === 8 ||
      tab === 9 ||
      tab === 10
    ) {
      formText = "submittedFrom";
    } else if (tab === 3) {
      formText = "scheduledFrom";
    } else if (tab === 4) {
      formText = "canceledFrom";
    }
  } else if (pageName === "DeletedRequirement") {
    if (tab === 1 || tab === 2) {
      formText = "createdFrom";
    }
  } else {
    formText = "from";
  }
  return formText;
};

export const bodyMailContent = (data, type) => {
  let content = { body: "", content: "", header: "" };
  if (type === "interviewer") {
    content.header = `Hi ${data?.interviewer?.name},\n\n${data?.employer[0]?.company?.name} has scheduled the interview of Candidate ${data?.candidate?.name} for ${data?.position?.title}.`;
    content.body = `For a smooth interview, kindly ensure of the below -\n\n1. Use of Chrome Browser is mandatory.\n\n2. Use your laptop and not a smartphone for the Interview.\n\n3. Check for good internet connectivity to avoid glitches and lag during the Interview.\n\n4. Please ensure your free from a noisy background or surroundings\n\n5. Login 5 mins early to ensure all of the above are in place.\n\nKindly accept the invite as a confirmation of acceptance from your end.\n\nFeel free to connect in case of any further clarifications required.\n\nRegards,\n${data?.employer[0]?.company?.name}`;
    content.content = `Below are the updated details.\nMeeting  link: ${data?.meetInviteDetails?.interviewerLink}?key=${data?.meetInviteDetails?.interviewerCode}\nMeeting Pin : ${data?.meetInviteDetails?.interviewerCode}`;
  } else {
    content.header = `Hi ${data?.candidate?.name},\n\nYour interview for ${
      data?.position?.title
    } at ${data?.employer[0]?.company?.name} has been scheduled on ${moment(
      data?.interviewDate
    ).format("DD-MM-YYYY")} at ${moment(data?.interviewTime, "HH:mm").format(
      "hh:mm A"
    )}`;
    content.body = `For a smooth interview, kindly ensure of the below --\n\n1. Use Chrome Browser in Windows or Safari in Mac for best interview experience.\n\n2. Use your laptop and not a smartphone for the Interview.\n\n3. Check for good internet connectivity to avoid glitches and lag during the Interview.\n\n4. Please ensure your free from a noisy background or surroundings.\n\n5. Login 5 mins early to ensure all of the above are in place.\n\n\nFeel free to connect in case of any further clarifications required.\n\nRegards,\n${data?.employer[0]?.company?.name}`;
    content.content = `Please find the updated details below to join the meeting:-\nMeeting  link: ${data?.meetInviteDetails?.candidateLink}`;
  }
  return content;
};
export const generateUrl = (urlMode) => {
  const envMode = process.env.REACT_APP_ENV;
  let frontendUrlPreFix, backendUrlPreFix, externalUrl;
  let finalUrl;
  if (envMode === "prod") {
    frontendUrlPreFix = "";
    backendUrlPreFix = "api.";
    externalUrl = "";
  } else if (envMode === "test" || envMode === "preprod") {
    frontendUrlPreFix = "test.v1.";
    backendUrlPreFix = "test.api.";
    externalUrl = "test.";
  } else {
    frontendUrlPreFix = "dev.v1.";
    backendUrlPreFix = "dev.api.";
    externalUrl = "dev.";
  }
  if (urlMode === "incserve") {
    finalUrl = `https://${externalUrl}incserve.incruiter.com`;
  } else if (urlMode === "website") {
    finalUrl = `https://${frontendUrlPreFix}incruiter.com`;
  } else if (urlMode === "incserve_admin") {
    finalUrl = `https://${frontendUrlPreFix}newadmin.incruiter.com/incserve/manage-interviews/scheduled`;
  } else if (urlMode === "incexit_admin") {
    finalUrl = `https://${frontendUrlPreFix}newadmin.incruiter.com/incexit/manage-interviews/scheduled`;
  } else if (urlMode === "admin") {
    finalUrl = `https://${frontendUrlPreFix}newadmin.incruiter.com/`;
  } else if (urlMode === "account") {
    finalUrl = `https://${frontendUrlPreFix}account.incruiter.com/`;
  } else if (urlMode === "incbot") {
    finalUrl = `https://${frontendUrlPreFix}incbot.incruiter.com`;
  } else if (urlMode === "career_backend") {
    finalUrl = `https://${backendUrlPreFix}incruiter.com/candidate-system/api/v1`;
  } else if (urlMode === "interview_backend") {
    finalUrl = `https://${backendUrlPreFix}incruiter.com/interview-system/api/v1`;
  } else if (urlMode === "skills_backend") {
    finalUrl = `https://${backendUrlPreFix}incruiter.com/skills-system/api/v1`;
  } else if (urlMode === "user_backend") {
    finalUrl = `https://${backendUrlPreFix}incruiter.com/user-auth-system/api/v1`;
  } else if (urlMode === "calendar_backend") {
    finalUrl = `https://${backendUrlPreFix}incruiter.com/calendar-system/api/v1`;
  } else if (urlMode === "subscription_backend") {
    finalUrl = `https://${backendUrlPreFix}incruiter.com/subscription-system/api/v1`;
  } else if (urlMode === "video_backend") {
    finalUrl = `https://${backendUrlPreFix}incruiter.com/video-feedback-system/api/v1`;
  } else if (urlMode === "generative-ai-system") {
    finalUrl = `https://${backendUrlPreFix}incruiter.com/generative-ai-system/api/v1`;
  } else if (urlMode === "main_url") {
    finalUrl = `https://${frontendUrlPreFix}incruiter.com`;
  }
  return finalUrl;
};

export const toastMessage = (type, message) => {
  toast[type](message, { autoClose: "5000", toastId: message });
};

export const Rs = (amount) => {
  let words = [];
  words[0] = "Zero";
  words[1] = "One";
  words[2] = "Two";
  words[3] = "Three";
  words[4] = "Four";
  words[5] = "Five";
  words[6] = "Six";
  words[7] = "Seven";
  words[8] = "Eight";
  words[9] = "Nine";
  words[10] = "Ten";
  words[11] = "Eleven";
  words[12] = "Twelve";
  words[13] = "Thirteen";
  words[14] = "Fourteen";
  words[15] = "Fifteen";
  words[16] = "Sixteen";
  words[17] = "Seventeen";
  words[18] = "Eighteen";
  words[19] = "Nineteen";
  words[20] = "Twenty";
  words[30] = "Thirty";
  words[40] = "Forty";
  words[50] = "Fifty";
  words[60] = "Sixty";
  words[70] = "Seventy";
  words[80] = "Eighty";
  words[90] = "Ninety";
  amount = amount.toString();
  let atemp = amount.split(".");
  let number = atemp[0].split(",").join("");
  let n_length = number.length;
  let words_string = "";
  if (n_length <= 9) {
    let n_array = [0, 0, 0, 0, 0, 0, 0, 0, 0];
    let received_n_array = [];
    for (let i = 0; i < n_length; i++) {
      received_n_array[i] = number.substr(i, 1);
    }
    for (let i = 9 - n_length, j = 0; i < 9; i++, j++) {
      n_array[i] = received_n_array[j];
    }
    for (let i = 0, j = 1; i < 9; i++, j++) {
      if (i === 0 || i === 2 || i === 4 || i === 7) {
        if (n_array[i] === 1) {
          n_array[j] = 10 + parseInt(n_array[j]);
          n_array[i] = 0;
        }
      }
    }
    let value = "";
    for (let i = 0; i < 9; i++) {
      if (i === 0 || i === 2 || i === 4 || i === 7) {
        value = n_array[i] * 10;
      } else {
        value = n_array[i];
      }
      if (value !== 0) {
        words_string += words[value] + " ";
      }
      if (
        (i === 1 && value !== 0) ||
        (i === 0 && value !== 0 && n_array[i + 1] === 0)
      ) {
        words_string += "Crores ";
      }
      if (
        (i === 3 && value !== 0) ||
        (i === 2 && value !== 0 && n_array[i + 1] === 0)
      ) {
        words_string += "Lakhs ";
      }
      if (
        (i === 5 && value !== 0) ||
        (i === 4 && value !== 0 && n_array[i + 1] === 0)
      ) {
        words_string += "Thousand ";
      }
      if (
        i === 6 &&
        value !== 0 &&
        n_array[i + 1] !== 0 &&
        n_array[i + 2] !== 0
      ) {
        words_string += "Hundred and ";
      } else if (i === 6 && value !== 0) {
        words_string += "Hundred ";
      }
    }
    words_string = words_string.split(" ").join(" ");
  }
  return words_string;
};

export const RsPaise = (n) => {
  let op;
  let nums = n.toString().split(".");
  let whole = Rs(nums[0]);
  if (nums[1] === null) nums[1] = 0;
  if (nums[1]?.length === 1) nums[1] = nums[1] + "0";
  if (nums[1]?.length > 2) {
    nums[1] = nums[1].substring(2, nums[1].length - 1);
  }
  if (nums.length === 2) {
    if (nums[0] <= 9) {
      nums[0] = nums[0] * 10;
    }
    let fraction = Rs(nums[1]);
    if (whole === "" && fraction === "") {
      op = "Zero Only";
    }
    if (whole === "" && fraction !== "") {
      op = fraction + "Paise Only";
    }
    if (whole !== "" && fraction === "") {
      op = "Rupees " + whole + " Only";
    }
    if (whole !== "" && fraction !== "") {
      op = "Rupees " + whole + "and " + fraction + " Paise Only";
    }
    return op;
  }
};

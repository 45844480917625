import { memo } from "react";

const Input = memo(
  ({
    className,
    name,
    value,
    onChange,
    placeholder,
    type,
    labelClass,
    inputClass,
    errorClass,
    error,
    label,
    isRequired,
    isEye,
    borderNone,
    handleShowHidePassword,
    showHidePassword,
    disabled,
    viewIcon,
    closeEyeIcon,
    onFocus,
    onBlur,
    min,
    max,
    onKeyUp,
  }) => {
    return (
      <>
        {label && (
          <label htmlFor={name} className={`c-label ${labelClass}`}>
            {label} {isRequired && <span className="asterisk f-12">&#42;</span>}
          </label>
        )}
        <div
          className={`position-relative ${inputClass} d-flex align-items-center c-input-container ${
            borderNone ? "border-0" : ""
          }`}
        >
          <input
            type={type}
            className={`${className} ${error ? "error" : ""}`}
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={(e) => {
              if (type === "number" && name === "discount") {
                const numValue =
                  e.target.value === "" ? "" : Number(e.target.value);
                if (numValue !== "" && (numValue < min || numValue > max)) {
                  return;
                }
              }
              onChange(e);
            }}
            disabled={disabled}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyUp={onKeyUp}
            min={min}
            max={max}
          />
          {isEye && (
            <div
              className="eye-icon cursor-pointer"
              onClick={handleShowHidePassword}
            >
              <img
                src={showHidePassword ? viewIcon : closeEyeIcon}
                className="img-fluid"
                alt="view icon"
              />
            </div>
          )}
        </div>
        {error && <div className={`invalid ${errorClass}`}>{error}</div>}
      </>
    );
  }
);

export default Input;
